import { Injectable } from '@angular/core';

//const themeA = require('../../shared/styles/themes/theme-a.scss')
//import _ = require( "lodash" );
@Injectable({
  providedIn: 'root'
})
export class ThemesService {

    styleTag: any;
    defaultTheme: string = 'A';

    constructor() {
        this.createStyle();
        this.setTheme(this.defaultTheme);
    }

    private createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
    }

    setTheme(name: any) {
        switch (name) {
            case 'A':
                //this.injectStylesheet(themeA);
                break;
        }
    }

    // since v9, content is available in 'default'
    injectStylesheet(css: any) {
        this.styleTag.innerHTML = css.default;
    }

    getDefaultTheme() {
        return this.defaultTheme;
    }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() label!: string;
  @Input() isLoading: boolean = false;
  @Input() btnStyle: string = 'primary';
  @Input() disabled!: boolean;
  @Input() styleClass!: string;
  @Input() icon!: string;
  @Input() title: string = '';

  @Output() onClick = new EventEmitter<void>();

  handleClick() {
    this.onClick.emit();
    console.log('click')
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateList'
})
export class TruncateListPipe implements PipeTransform {
  transform(value: string[], maxLength: number): { displayed: string[]; hidden: string[] } {
    if (!value) {
      return { displayed: [], hidden: [] };
    }

    if (value.length <= maxLength) {
      return { displayed: value, hidden: [] };
    }

    const displayed = value.slice(0, maxLength);
    const hidden = value.slice(maxLength);

    return { displayed, hidden };
  }
}


const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

const headingMain = {
    text: 'Main Navigation',
    heading: true
};

export const menu = [
    {
      text: 'Dashboard',
      link: '/admin/dashboard',
      icon: 'fas fa-th-large'
    },
    {
      text: 'Schools',
      link: '/admin/schools',
      icon: 'fas fa-school'
    },
    {
      text: 'SST Insights',
      link: '/admin/insights',
      icon: 'fas fa-chart-line'
    },
    {
      text: 'SST Tools',
      link: '/admin/tools',
      icon: 'fas fa-image'
    },
    {
      text: 'Resources',
      link: '/admin/resources',
      icon: 'fas fa-copy'
    },

    {
      text: 'Preferences',
      link: '/admin/preferences',
      icon: 'fas fa-cog'
    },
    {
      text: 'Logout',
      link: '/admin/logout',
      icon: 'fas fa-sign-out'
    },
  ];

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { GSearchService } from 'src/app/shared/services/g-search.service';
import { SstStateService } from 'src/app/shared/services/sst-state.service';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
})
export class SearchDropdownComponent implements OnChanges {
  @Input() recentList!: any[];
  @Input() mobileView: boolean = false;
  @Input() isLocation: boolean = false;
  @Input() showCurrentLocation: boolean = true;
  @Output() handleSelectedItem = new EventEmitter<any>();
  @Output() handleCurrentLocation = new EventEmitter<any>();
  @Output() handleCurrentLocationError = new EventEmitter<any>();

  isLoading: any = {};

  disableCurrLocation: boolean = false;

  constructor(
    private gService: GSearchService,
    private sst: SstStateService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.recentList = this.cleanRecentList(this.recentList);
  }

  // ngAfterViewInit(): void {
  //   console.log('recetnlist', this.recentList)
  //   this.recentList = this.cleanRecentList(this.recentList);
  // }

  cleanRecentList(recentList: any[]) {
    const cleanList = recentList.filter((a) => {
      return a.value.length > 0 && typeof a.value === 'string';
    });

    return cleanList;
  }

  async loadLocation() {
    this.isLoading['currLocation'] = true;
    const successCallback = async (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      if (!this.gService.isWithinLausdBoundingBox(lat, lng)) {
        // If not inside LAUSD's region
        this.toastr.error(`Current Location is outside of LAUSD Region`);
        this.isLoading['currLocation'] = false;
        this.disableCurrLocation = true;
        this.handleCurrentLocationError.emit(true);
        return;
      }
      this.handleCurrentLocationError.emit(false);
      try {
        const searchRes = await lastValueFrom(
          this.gService.searchByLatLong(
            position.coords.latitude,
            position.coords.longitude
          )
        );
        const cleanAddress = searchRes.replace(/#\d+\b/g, '').trim(); // Removes any apt unit numbers
        console.log(searchRes, 'response');
        this.isLoading['currLocation'] = false;
        this.sst.isCurrentLocation$.next(true);
        this.handleCurrentLocation.emit({
          source: 'address',
          id: cleanAddress.replace(/ /g, '-').replace(/,/g, ''),
        });
        // this.sst.actionSearch(response);
      } catch (error) {
        this.isLoading['currLocation'] = false;
      }
      // this.gService
      //   .searchByLatLong(position.coords.latitude, position.coords.longitude)
      //   .subscribe(
      //     (response) => {
      //       // var element = document.getElementById('typeahead') as any;
      //       // element.value = response;
       
      //     },
      //     (error) => {
      //       this.isLoading['currLocation'] = false;
      //     }
      //   );
    };

    const errorCallback = (error) => {
      this.toastr.error(`Location Services not enabled`)
      this.isLoading['currLocation'] = false;
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }

  addInput(input) {
    const item = {
      id: input.value.replace(/ /g, '-').replace(/,/g, ''),
      name: input.value,
      source: input.key,
    };
    this.sst.isCurrentLocation$.next(false);
    this.handleSelectedItem.emit(item);
  }
}

import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { map, Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  baseUrl: string = 'https://stg.api.lausd.net/customers'; //"https://localhost:44315"


  constructor(
    private api: ApiService,
    private oauthService: OAuthService
  ) { }


  getPicture(id:any): Observable<any>{
    let path = `${this.baseUrl}/v1/students/${id}/picture`;

    var token = this.oauthService.getAccessToken()
    return this.api
      .getWithHeaders(path,{
        'Authorization':'Bearer '+token
      })
      .pipe(map((response) => response as any[]));

    return this.api.cached(path,{spinner:'no'}).pipe(
      map((response) => response as any)
      );
  }
}

export * from './health.service';
import { HealthService } from './health.service';
export * from './rsi.service';
import { RsiService } from './rsi.service';
export * from './school.service';
import { SchoolService } from './school.service';
export * from './sst.service';
import { SstService } from './sst.service';
export * from './sstCms.service';
import { SstCmsService } from './sstCms.service';
export * from './sstInfo.service';
import { SstInfoService } from './sstInfo.service';
export const APIS = [HealthService, RsiService, SchoolService, SstService, SstCmsService, SstInfoService];

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tkToUtk'
})
export class TkToUtkPipe implements PipeTransform {

  transform(value: string): string {
    if (value.includes('TK') || value.includes('Tk') || value.includes('tk')) {
      return value.replace('TK', 'UTK');
    }
    return value;
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent implements OnInit {
  imageLoaded = false;

  @Output() onImageLoad = new EventEmitter();
  // Your image URL
  @Input() imageUrl: string | null = null;
  @Input() imageClass: string = '';

  ngOnInit(): void {
    console.log(this.imageUrl);
  }
  // Event handler for image load
  handleImageLoad() {
    setTimeout(() => (this.imageLoaded = true), 0);
    // this.onImageLoad.emit();
    console.log('image load');
  }
}

<div class="btn-container">
  <!-- <button
    *ngIf="!isCms"
    (click)="openModal()"
    type="button"
    class="btn btn-sm btn-outline-secondary left-btn"
  >
    Language
  </button>
  <button
    *ngIf="!isCms"
    (click)="feedback()"
    type="button"
    class="btn btn-sm btn-outline-success"
  >
    Feedback
  </button>
  <button
    id="tour-shortcut"
    (click)="startTour()"
    type="button"
    class="btn btn-sm btn-outline-secondary right-btn"
    [ngClass]="{ 'br-corners': isCms }"
  >
    Help Tips
  </button> -->

  <button
    #fab="matMenuTrigger"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="FAB"
    class="fab-btn"
  >
    <mat-icon *ngIf="!fab.menuOpen" fontSet="fa" fontIcon="fa-plus"></mat-icon>
    <mat-icon *ngIf="fab.menuOpen" fontSet="fa" fontIcon="fa-xmark"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="!isCms" (click)="openModal()">
      <span>Language</span>
    </button>
    <button mat-menu-item *ngIf="!isCms" (click)="feedback()">
      <span>Feedback</span>
    </button>
    <button *ngIf="isResultsPage" mat-menu-item (click)="startTour()">
      <span>Help Tips</span>
    </button>
  </mat-menu>
</div>

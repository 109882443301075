import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appImageFallback]'
})
export class ImageFallbackDirective {

  @Input() appImageFallback: string = ''

  defaultImg: string = '/assets/img/icons/default.png'
  constructor(private eRef: ElementRef) {

  }

  get isLausdLogo() {
    return this.appImageFallback == 'assets/img/lausd-logo.png'
  }

  @HostListener('error')
  loadCallbackImageOnError(){
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.appImageFallback ? this.appImageFallback : this.defaultImg;
    if(this.isLausdLogo) {
      element.style.opacity = "0.5";
      element.style.objectFit = "contain";
      element.style.transform = "scale(0.75)"
    }
  }


}

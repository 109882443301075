import { SstStateService } from 'src/app/shared/services/sst-state.service';
import { forEach } from 'lodash';
import { MdlCmsSchoolInfo } from './../api/facilities/model/mdlCmsSchoolInfo';
import { MdlSstSchoolInfo } from './../api/facilities/model/mdlSstSchoolInfo';
import { catchError, concatMap, map } from 'rxjs/operators';
import { SstInfoService } from './../api/facilities/api/sstInfo.service';
import { SstService } from './../api/facilities/api/sst.service';
import { SchoolService } from './../api/facilities/api/school.service';
import { EsRow } from './../models/es';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  forkJoin,
  from,
  iif,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { runInThisContext } from 'vm';
import * as _ from 'lodash';
import { timeStamp } from 'console';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CompareService {
  compareArr: MdlSstSchoolInfo[] = [];
  compareArr$: BehaviorSubject<MdlSstSchoolInfo[]> = new BehaviorSubject<
    MdlSstSchoolInfo[]
  >([]);

  private readonly compareStateKey = 'compareState';
  private readonly expirationKey = 'compareStateExpiration';
  private readonly expirationTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds


  showTray$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  compare: Set<string> = new Set<string>();
  compareCount: string[] = [];
  compareCount$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  compareList: Array<MdlSstSchoolInfo> = new Array<MdlSstSchoolInfo>();
  compareList$: BehaviorSubject<MdlSstSchoolInfo[]> = new BehaviorSubject<
    MdlSstSchoolInfo[]
  >([]);

  comparing: Array<MdlSstSchoolInfo> = new Array<MdlSstSchoolInfo>();
  comparing$: BehaviorSubject<MdlSstSchoolInfo[]> = new BehaviorSubject<
    MdlSstSchoolInfo[]
  >([]);
  compareChanged$:BehaviorSubject<{op:string,schoolId:string} | null> = new BehaviorSubject<{op:string,schoolId:string} | null>(null);
  constructor(
    private schooService: SchoolService,
    private sstService: SstInfoService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.loadCompareState();
  }

  private storeCompareState() {
    const serializedState = JSON.stringify(this.compareArr);
    localStorage.setItem(this.compareStateKey, serializedState);
    localStorage.setItem(this.expirationKey, Date.now().toString());
  }

  private loadCompareState() {
    const serializedState = localStorage.getItem(this.compareStateKey);
    const expiration = localStorage.getItem(this.expirationKey);
    const expirationTimestamp = expiration ? parseInt(expiration, 10) : 0;

    if (
      serializedState &&
      Date.now() - expirationTimestamp < this.expirationTime
    ) {
      this.compareArr = JSON.parse(serializedState);
    } else {
      // Default or empty state when expired or not found
      this.compareArr = [];
      this.clearCompareState();
    }

    this.compareArr$.next(this.compareArr);
    this.compareArr.map(c=>c.document?.sst.costCenter).forEach((costCenter)=>{
      this.compareChanged$.next({op:'+',schoolId:costCenter!})
    });
    //this.compareCount$.next(schoolIds as any)
  }

  public clearCompareState() {
    localStorage.removeItem(this.compareStateKey);
    localStorage.removeItem(this.expirationKey);
    this.compareList = []
    this.compareArr = []
  }

  updateCompare(school: MdlSstSchoolInfo) {

    const schoolId = school.document?.sst.costCenter;
    if (
      !!this.compareArr.find(
        (school) => school.document?.sst.costCenter == schoolId
      )
    ) {
      this.compareArr = this.removeFromCompare(school);
    } else {
      this.compareArr = this.addToCompare(school);
    }
    this.compareArr$.next(this.compareArr);
    this.storeCompareState();
    console.log(this.compareArr, 'this.compareArr');
    console.log(this.compareArr$.getValue(), '$');
  }

  addToCompare(school: MdlSstSchoolInfo) {
    const compare = [...this.compareArr];
    compare.push(school);
    this.compareChanged$.next({
      op:'+',schoolId: school.document?.sst.costCenter!
    })
    return compare;
  }

  removeFromCompare(school: MdlSstSchoolInfo) {
    const schoolId = school.document?.sst.costCenter;
    const compare = [
      ...this.compareArr.filter(
        (school) => school.document?.sst.costCenter != schoolId
      ),
    ];
    this.compareChanged$.next({
      op:'-',schoolId: school.document?.sst.costCenter!
    })
    return compare;
  }

  getCompareLength() {
    return this.compareArr$.getValue().length;
  }

  isComparing(school: MdlSstSchoolInfo) {
    const schoolId = school.document?.sst.costCenter;
    return !!this.compareArr.find(
      (school) => school.document?.sst.costCenter == schoolId
    );
  }

  actionCompare() {
    if(this.getCompareLength() < 2) {
      this.toastr.error('Please add at least 2 schools before comparing');
      return
    }
    let compareList = this.compareArr$
      .getValue()
      .map((item) => `${item.document?.sst.costCenter}`)
      .join(',');
    this.router.navigate([`compare`], {
      queryParams: { schools: compareList },
    });
  }

  ///////////////

  compareLength() {
    return this.compareList.length;
  }

  addCompare(schoolIds: string[]) {
    schoolIds.forEach((id) => {
      if (id) this.compare.add(id);
      this.compareCount.push(id);
    });
    this.compareCount$.next(_.uniq(this.compareCount));
  }

  removeCompare(schoolIds: string[]) {
    schoolIds.forEach((id) => {
      if (id) this.compare.delete(id);
      this.compareCount = _.filter(this.compareCount, (item) => item != id);
    });
    this.compareList = this.compareList.filter((school) => {
      school.document?.sst.costCenter !== schoolIds[0];
    });
    this.compareList$.next(this.compareList);
    this.compareCount$.next(this.compareCount);
  }

  updateComparing(school: MdlSstSchoolInfo) {

    //For the dropdown list
    let comparing: MdlSstSchoolInfo[] = [...this.comparing];
    if (
      _.findIndex(
        comparing,
        (x) => x.document?.sst.costCenter == school.costCenter
      ) === -1
    ) {
      comparing.push(school);
    } else {
      comparing = comparing.filter(
        (s) => s.document?.sst.costCenter !== school.costCenter
      );
    }
    this.comparing = [...comparing];
    this.comparing$.next(this.comparing);
  }

  actionRemoveAll() {
    this.compare = new Set<string>();
    this.compareCount = [];
    this.compareCount$.next(this.compareCount);
    this.compareList = [];
    this.compareList$.next([]);
    this.compareArr$.next([]);


  }

  compareSchools() {
    console.log(this.compare);
    let requests = [...this.compare].map((schoolId) =>
      this.sstService.v1SstSchoolIdGet(schoolId)
    );

    requests = _.uniq(requests);
    forkJoin(requests).subscribe((response) => {
      response.forEach((element) => {
        this.compareList.push(element);
      });
      this.compareList$.next([...this.compareList]);
    });
    this.compareList$.next([...this.compareList]);
  }
  schoolMap(row: MdlSstSchoolInfo) {
    var schoolSchema = {
      title: '',

      pageBody: {
        addressInfo: row.document!.sst.address,
        grades: row.document!.sst.gradesOffered,
      },
    };
    return schoolSchema;
  }

  mapFromEsrow(rows: EsRow[]): Observable<MdlSstSchoolInfo[]> {
    if (rows.length == 0) return of([] as MdlSstSchoolInfo[]);
    let requests = rows.map((rw) => this.sstService.v1SstSchoolIdGet(rw._id).pipe(
      catchError(error => {
      // Handle or ignore the error here
      return of(null); // Replace with your desired behavior
    })));


    return forkJoin(requests).pipe(
      map((schoolInfo) => {
        let validResults: any = [];
        let result = schoolInfo.map((item, index) => {
          if(item != null){
            item.locationCode = item.document?.sst.preferredLocationCode;
            item.score = rows[index]._score;
            item.maxScore = rows[index]._maxScore;
            item.distance = rows[index].distance;
            item.rank = index;
            validResults.push(item as any)
          }

        });
        return validResults;
      })
    );

  }

  disableAtMax(max: number) {
    return this.getCompareLength() >= max
  }
  compareWarning() {
    this.toastr.error('Cannot compare more than 4 schools at a time')
  }

}

import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { BrowserModule } from '@angular/platform-browser';
import { AppConfigService } from './shared/services/app-config.service';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { ToastrModule } from 'ngx-toastr';


import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from './shared/api/facilities';
import { MAT_MENU_SCROLL_STRATEGY, MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/menu';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const appInitializerFn = (appConfig: AppConfigService) => {
  return (): Promise<any> => {
    return appConfig.loadAppConfig();
  };
};
const apiServiceConfig = function (
  authService: OAuthService,
  appConfig: AppConfigService
) {
  return new Configuration({
    basePath: appConfig.appConfig?.environment.endPoints.facilitiesServices,
    accessToken: authService.getAccessToken.bind(authService),
  });
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ApiModule, //facilities,
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    OAuthModule.forRoot(),
    ToastrModule.forRoot(),
    //FontAwesomeModule,
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: Configuration,
      useFactory: apiServiceConfig,
      deps: [OAuthService, AppConfigService],
      multi: false,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GSearchService } from './g-search.service';

@Injectable({
  providedIn: 'root',
})
export class TranslatorService {
  private defaultLanguage: string = 'en';
  lastLng = null;
  previousLng: string = 'en';
  currentLng: string = 'en';
  defaultLng: string = 'en';
  baseUrl: string = '';

  get lngCache():string {
    return window.localStorage['lng'] ?? '';
  }

  fabLng$: BehaviorSubject<string> = new BehaviorSubject<string>('en');
  currLng$: BehaviorSubject<string> = new BehaviorSubject<string>('en');

  private availablelangs = [
    { code: 'en', text: 'English', displayText: 'English' },
    { code: 'es', text: 'Spanish', displayText: 'Español' },
    { code: 'it', text: 'Italian', displayText: 'Italiano' },
    { code: 'fa', text: 'Persian', displayText: 'فارسی' },
    { code: 'ko', text: 'Korean', displayText: '한국어' },
    { code: 'hy', text: 'Armenian', displayText: 'Հայերեն' },
    { code: 'ru', text: 'Russian', displayText: 'Рyсский' },
    { code: 'tl', text: 'Tagalog', displayText: 'Wikang Tagalog' },
    { code: 'vi', text: 'Vietnamese', displayText: 'Tiếng Việt' },
    { code: 'zh', text: 'Chinese', displayText: '中文' },
  ];

  constructor(
    private api: ApiService,
    private appConfig: AppConfigService,
    private gSearch: GSearchService
  ) {
    this.baseUrl =
      appConfig.appConfig!.environment.endPoints.communicationsServices;

    const _currLang = this.currLng$.subscribe((res) => {
      this.gSearch.updateGoogleMapsScript(res);
    });
  }

  processTranslate() {
    var words: string[] = [];
    var tags: Element[] = [];
    var currentLng = this.currentLng;
    var elements = document.querySelectorAll(
      `body [v3-translate],[v3-translate-child]`
    );
    if (currentLng == this.defaultLng) return;
    elements.forEach((e) => {
      var lang = e.getAttribute('v3-translate');
      var isChild = e.getAttribute('v3-translate-child');
      if (lang != currentLng) {
        if (currentLng != this.defaultLng) {
          var translateSource = e.getAttribute('v3-translate-source');
          if (translateSource == undefined) {
            e.setAttribute('v3-translate-id', e.textContent || '');
          }
        }
        if (e.childNodes.length == 1) {
          if (e.childNodes[0].nodeValue) {
            tags.push(e);
            words.push(e.childNodes[0].nodeValue);
          }
        }
        if (isChild == '' && e.childNodes.length > 1) {
          for (let i = 0; i < e.childNodes.length; i++) {
            if (e.childNodes[i].nodeValue) {
              tags.push(e.childNodes[i] as any);
              words.push(e.childNodes[i].nodeValue!);
            } else {
              tags.push(e.childNodes[i] as any);
              words.push(e.childNodes[i].textContent!);
            }
          }
        }
      }
    });
    if (tags.length > 0 && this.previousLng != currentLng) {
      let translatorObs = this.translateWords(words, this.previousLng);
      if (translatorObs)
        translatorObs.subscribe(
          (result: any) => {
            result.forEach((r: any, i: any) => {
              if (tags[i]['setAttribute']) {
                tags[i].setAttribute('v3-translate', currentLng);
                tags[i].innerHTML = r.target;
              } else {
                tags[i].nodeValue = r.target;
              }
            });
          },
          (err) => {}
        );
    }
  }

  loading: boolean = false;

  updateTranslate() {
    //Updates translation on page when navigating
    var words: string[] = [];
    var tags: Element[] = [];
    var currentLng = this.currLng$.getValue();
    var elements = document.querySelectorAll(
      `body [v3-translate],[v3-translate-child]`
    );
    if (currentLng == 'en') return;
    elements.forEach((e) => {
      var lang = e.getAttribute('v3-translate');
      if (lang != currentLng) {
        if (currentLng != this.defaultLng) {
          var translateSource = e.getAttribute('v3-translate-source');
          if (translateSource == undefined) {
            e.setAttribute('v3-translate-id', e.textContent || '');
          }
        }
        if (e.childNodes.length == 1) {
          if (e.childNodes[0].nodeValue) {
            tags.push(e);
            words.push(e.childNodes[0].nodeValue);
          }
        }
      }
    });
    if (tags.length > 0) {
      let translatorObs = this.translateWords(words, this.previousLng);
      if (translatorObs) this.loading = true;
      translatorObs.subscribe((result: any) => {
        this.loading = false;
        result.forEach((r: any, i: any) => {
          tags[i].setAttribute('v3-translate', currentLng);
          tags[i].innerHTML = r.target;
        });
      });
    }
  }

  translateWords(words: any, source: any) {
    let lng = this.currLng$.getValue();
    if (!this.previousLng) this.previousLng = this.defaultLng;
    if (lng == null) lng = 'en';
    if (source == '') source = 'en';
    let path = `${this.baseUrl}/v1/translator?source=en&target=${lng}`;
    return this.api.post(path, words).pipe(map((response) => response as any));
  }

  initLng() {
    if (this.lngCache) {
      this.updateLng(this.lngCache);
    }
  }

  updateLng(lng: string) {
    this.currentLng = lng;
    this.fabLng$.next(lng);
    this.currLng$.next(lng);
    if (this.currentLng == 'en') {
      location.reload();
      return;
    }

    let self = this;

    setTimeout(() => {
      this.processTranslate();
      this.previousLng = lng;
    }, 1500);
  }
}

<div id="adv-search-keyword"
  [ngClass]="{ 'input-error': inputError['keywords'], 'header': !mobileModal, 'collapsed': collapsed}">
  <div class="keyword-mobile search-select" [ngClass]="{'compare': isCompareSearch}"
    (click)="$event.stopImmediatePropagation()">
    <!-- <span class="ng-select-placeholder">"Art, Magnet, Scoccer" - "Santa Monica" - "900249"</span> -->
    <span class="ng-select-title">
      <span v3-translate>Search school or keywords</span>
    </span>
    <ng-select #ngSelect [items]="suggestions" bindLabel="name" bindValue="id" groupBy="source" class=".search-input"
      [typeahead]="typeahead$" [minTermLength]="3" [isOpen]="!showDropOptions && ngSelect.focused"
      (keydown.enter)="selectedItem($event, true); handleEnter()" (search)="onInputSearch($event)"
      (focus)="sstState.updateDropOptions('keyword', true); showDropOptions = true" (clear)="ngSelect.blur()"
      [clearable]="false">
      <ng-template ng-label-tmp let-item="item">
        <!-- <b>{{ item.name }}</b> is cool -->
      </ng-template>
      <ng-template ng-header-tmp>
        <div class="dropdown-keyword mb-3">
          <b>Keyword</b>
          <div #keyword id="keyword-suggestion" role="button" class="mt-2 pl-2"
            (click)="handleKeywordChange(ngSelect.searchTerm); ngSelect.blur(); selectKeyword(ngSelect.searchTerm)">
            <span>
              <i class="fa-solid fa-magnifying-glass mr-1"></i>
            </span>
            {{ ngSelect.searchTerm }}
          </div>
        </div>
        <!-- <span class="instruction"
          >Select from this list to find the best match</span
        > -->
      </ng-template>
      <ng-template ng-optgroup-tmp let-item="item" let-index="index">
        <div class="dropdown-group-title" [ngClass]="{ 'mt-3': index > 0 }">
          {{
          item.source == "zip"
          ? "Zip Code"
          : (item.source || "Unnamed group" | titlecase)
          }}
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="dropdown-item" title="{{ item.name }}" (click)="
            selectedItem(item, false); ngSelect.blur(); ngSelect.clearModel()
          ">
          <span>
            <i class="fa mr-2" [ngClass]="{
                'fa-location-dot': item.source == 'address',
                'fa-school': item.source == 'school',
                'fa-map-pin': item.source == 'zip',
                'fa-city': item.source == 'location'
              }"></i>
          </span>
          {{ item.name }}
        </div>
      </ng-template>
    </ng-select>

    <div *ngIf="this.ngSelect.searchInput.nativeElement.value.length > 0" class="close-btn" (click)="clearField()">
      <i class="fas fa-times-circle"></i>
    </div>

    <app-search-dropdown #optsDropdown *ngIf="showDropOptions" [recentList]="recentList" (handleSelectedItem)="
        handleKeywordChange($event.name);
        sstState.updateDropOptions('keyword', false);
        handleClose()
      " (handleCurrentLocation)="selectedItem($event)" [showCurrentLocation]="false"
      [mobileView]="device.width <= 428"></app-search-dropdown>
  </div>
</div>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, of, share, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,

  ) {

  }

  get(url: string): Observable<object> {
    let request = url;
    return this.httpClient.get(request).pipe(catchError(err=>of(err)));
  }

  getWithParams(url: string, params: any): Observable<object> {
    let request = url;
    return this.httpClient.get(request, { params: params }).pipe(catchError(err=>of(err)));
  }
  getWithHeaders(url: string, headers: any): Observable<object> {
    let request = url;
    return this.httpClient.get(request, { headers: headers }).pipe(catchError(err=>of(err)));
  }

  post(url: string, body: any): Observable<object> {
    let request = url;
    return this.httpClient.post(request, body).pipe(catchError(err=>of(err)));;
  }
  postHeaders(url: string, body: any, header: any): Observable<object> {
    let request = url;
    return this.httpClient.post(request, body,{headers: header}).pipe(catchError(err=>of(err)));;
  }

  getNoCache(url: string): Observable<object> {
    let headers = new HttpHeaders({
      "Cache-Control":
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
      Pragma: "no-cache",
      Expires: "0",
    });
    let request = url;
    return this.httpClient.get(request, { headers: headers }).pipe(catchError(err=>of(err)));;
  }

  cachedObservable: Array<Observable<any>> = [];
  cacheRequests = [];
  cached(url: string, params: any): Observable<any> {
    let observable: Observable<any>;
    let cacheReqs = this.cacheRequests as any;
    let cachedObs = this.cachedObservable as any;
    if (cacheReqs[url]) {
      observable = of(cacheReqs[url]);
    } else if (cachedObs[url]) {
      observable = cachedObs[url];
    } else {
      cachedObs[url] = this.httpClient.get<any>(url).pipe(
        tap((res) => (cacheReqs[url] = res)),
        share(),
        finalize(() => (cachedObs[url] = null))
      );
      observable = cachedObs[url];
    }
    return observable;
  }
  patch(url: string, body: any): Observable<object> {
    let request = url;
    return this.httpClient.patch(request, body).pipe(catchError(err=>of(err)));;
  }
  patchHeader(url: string, body: any, header: any): Observable<object> {
    let request = url;
    return this.httpClient.patch(request, body,{ headers: header}).pipe(catchError(err=>of(err)));;
  }

}

<div class="form-group p-3">
  <label v3-translate class="text-bold">Language</label>
  <fieldset aria-label="Language settings" class="mb-0 pb-0">
    <legend></legend>
    <mat-radio-group
      color="primary"
      [formControl]="language"
      aria-label="Select a Language"
    >
      <mat-radio-button
        class="mr-2"
        *ngFor="let lang of languages"
        [value]="lang.value"
        >{{ lang.lang }}
      </mat-radio-button>
    </mat-radio-group>
  </fieldset>

  <section
    *ngIf="language.disabled"
    class="mt-4 pb-0 d-flex justify-content-center"
  >
    <span>
      The language is set to {{ getLng(currLng) | titlecase }}. To reset your
      language configuration settings 
      <a href="javascript:;" (click)="refresh()">click here</a>.</span
    >
  </section>
</div>

<div class="w-100 d-flex justify-content-end p-2">
  <span
    class="btn btn-light cancel"
    type="button"
    (click)="cancel()"
    v3-translate
  >
    Cancel
  </span>
  <button
    class="btn btn-primary save ml-2"
    type="button"
    (click)="save()"
    v3-translate
    [disabled]="language.disabled || currLng == language.value"
  >
    Save
  </button>
</div>

import { RoleService } from './../routes/admin/services/role.service';
import { SstStateService } from './../shared/services/sst-state.service';
import { Subscription, filter } from 'rxjs';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit,OnDestroy {


  isCms: boolean = false;
  constructor(private router: Router, ) {
    this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event) => {
      let ev = event as any;
      const url = ev.url as string;
      this.isCms = url.includes("/admin");
    });
  }
  ngOnDestroy(): void {
    
  }

  ngOnInit() {


  }
  
}

import { Component, Input, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopoverComponent {

  @Input() text: string = '';

}

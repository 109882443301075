<div class="image-container d-flex flex-column justify-content-center">
  <!-- Display the image when it's loaded -->

  <img
    [src]="imageUrl"
    (load)="handleImageLoad()"
    alt="Image"
    class="{{ imageClass }}"
  />
  <!-- Display the loader when the image is not loaded -->
  <app-spinner *ngIf="!imageLoaded" class="text-center" [size]="2"></app-spinner>
</div>

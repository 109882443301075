import { TranslatorService } from 'src/app/shared/services/translator.service';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { timer } from 'rxjs';
import { SstStateService } from 'src/app/shared/services/sst-state.service';
import { AttendEvent, VisitEvent } from '../api/facilities';
import { SstService } from './../api/facilities/api/sst.service';
import { FavoriteEvent } from './../api/facilities/model/favoriteEvent';
import { MdlSstSchoolInfo } from './../api/facilities/model/mdlSstSchoolInfo';
import { SearchResult } from './../api/facilities/model/searchResult';
import { SearchScope } from './../api/facilities/model/searchScope';
import { SstSession } from './../api/facilities/model/sstSession';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class SstSessionLogService {
  db = window.localStorage;
  sessionId: string | null = null;
  session: SstSession | null = null;
  source = timer(1000, 30000);
  lng: string = 'en';

  subscribe = this.source.subscribe((val) => {
    this.pushLog();
  });
  constructor(
    private sstService: SstService,
    private sstStateService: SstStateService,
    private appConfig: AppConfigService,
    private translator: TranslatorService
  ) {
    this.sstService.configuration.basePath =
      appConfig.appConfig?.environment.endPoints.facilitiesServices;
    this.translator.currLng$.subscribe((lng) => {
      this.lng = lng;
    });
  }
  lastRows: any = [];
  _result = this.sstStateService.schoolSearchResult$.subscribe((rows) => {
    if (rows != null && this.sstStateService.newQuery) {
      this.searchRequest(rows);
      this.lastRows = rows;
    }
  });

  init() {
    this.sessionId = this.db.getItem('session_id');
    if (this.sessionId == null) {
      this.sessionId = Guid.create().toString();
      this.db.setItem('session_id', this.sessionId);
    }
    this.session = {
      id: this.sessionId,
      searchScopes: [],
    };
  }
  addVisit(schoolCostCenterId: any) {
    let entry: VisitEvent = {
      timestamp: new Date().toISOString(),
      costCenter: schoolCostCenterId,
    };
    if (this.session!.searchScopes!.length > 0) {
      let size = this.session!.searchScopes?.length! - 1;
      let visits = this.session!.searchScopes![size].results![0].visits;
      if (Array.isArray(visits)) {
        this.session!.searchScopes![size].results![0].visits = visits.concat([
          entry,
        ]);
      } else {
        this.session!.searchScopes![size].results![0].visits = [entry];
      }
    } else {
      this.searchRequest(this.lastRows);
      this.addVisit(schoolCostCenterId);
    }
  }

  addFilters(entries: any) {
    if (this.session!.searchScopes!.length > 0) {
      let size = this.session!.searchScopes?.length! - 1;
      let filters = this.session!.searchScopes![size].filters;
      if (Array.isArray(filters)) {
        this.session!.searchScopes![size].filters = filters.concat(entries);
      } else {
        this.session!.searchScopes![size].filters = entries;
      }
    } else {
      this.searchRequest(this.lastRows);
      this.addFilters(entries);
      console.log(entries);
    }
  }
  addAttend(schoolCostCenterId: any) {
    let entry: AttendEvent = {
      timestamp: new Date().toISOString(),
      costCenter: schoolCostCenterId,
    };
    if (this.session!.searchScopes!.length > 0) {
      let size = this.session!.searchScopes?.length! - 1;
      let attendees = this.session!.searchScopes![size].results![0].attendees;
      if (Array.isArray(attendees)) {
        this.session!.searchScopes![size].results![0].attendees =
          attendees.concat([entry]);
      } else {
        this.session!.searchScopes![size].results![0].attendees = [entry];
      }
    } else {
      this.searchRequest(this.lastRows);
      this.addAttend(schoolCostCenterId);
    }
  }
  addFavorite(schoolCostCenterId: any) {
    let entry: FavoriteEvent = {
      timestamp: new Date().toISOString(),
      costCenter: schoolCostCenterId,
    };
    if (this.session!.searchScopes!.length > 0) {
      let size = this.session!.searchScopes?.length! - 1;
      let favorites = this.session!.searchScopes![size].results![0].favorites;
      if (Array.isArray(favorites)) {
        this.session!.searchScopes![size].results![0].favorites =
          favorites.concat([entry]);
      } else {
        this.session!.searchScopes![size].results![0].favorites = [entry];
      }
    } else {
      this.searchRequest(this.lastRows);
      this.addFavorite(schoolCostCenterId);
    }
  }

  pushLog() {
    if (this.session!.searchScopes!.length > 0) {
      this.sstService.v1SstLogPost(this.session!).subscribe((response) => {
        this.session!.searchScopes!.pop();
      });
    }
  }

  searchRequest(rows: MdlSstSchoolInfo[]) {
    let result: SearchResult = {
      count: rows.length,
      compares: [],
      favorites: [],
      visits: [],
      schoolLocations: [],
    };
    let searchSettings = this.sstStateService.searchPageSettings;
    const filters = searchSettings.filters as any[];
    let searchScope: SearchScope = {
      grades: [''],
      keywords: [searchSettings.keywords],
      filters: [],
      language: this.translator.currentLng,
      location: searchSettings.addressName,
      radius: searchSettings.distance,
      results: [
        {
          count: rows.length,
          schoolLocations: rows.map(
            (a) => a.document?.sst.costCenter as string
          ),
        },
      ],
      searchType: '',
      timeStamp: new Date().toISOString(),
    };
    if (searchSettings.grades) {
      if (Array.isArray(searchSettings.grades)) {
        searchScope.grades = searchSettings.grades.map((a) => `Grade ${a}`);
      }
    }
    if (Array.isArray(filters) && filters.length > 0) {
      searchScope.filters = filters.map((f) => f.name)
    }

    this.session?.searchScopes?.push(searchScope);
  }
}

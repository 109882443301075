import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bulletList'
})
export class BulletListPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    if (value.includes('|')) {
      const items = value.split('|');
      const bulletedList = items.map(item => `<li>${item}</li>`).join('');
      return `<ul>${bulletedList}</ul>`;
    } else {
      return `<ul><li><span v3-translate>${value}</span></li></ul>`
    }
  }
}

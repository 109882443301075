import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { SstStateService } from '../../services/sst-state.service';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { GSearchService } from '../../services/g-search.service';
import { Router } from '@angular/router';
import { DeviceService } from '../../services/device.service';
import { SstCmsProviderService } from '../../services/sst-cms-provider.service';
import { CompareService } from '../../services/compare.service';
import { MobileSstStateService } from '../../services/mobile-sst-state.service';

@Component({
  selector: 'app-keyword-adv-search',
  templateUrl: './keyword-adv-search.component.html',
  styleUrls: ['./keyword-adv-search.component.scss'],
})
export class KeywordAdvSearchComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnChanges
{
  @ViewChild('ngSelect') ngSelect!: NgSelectComponent;
  @Output() onKeywordUpdate = new EventEmitter();
  @Output() onSelectedItem = new EventEmitter();
  @Output() onSelectedKeyword = new EventEmitter();
  @Output() onClearField = new EventEmitter();
  @Output() onclose = new EventEmitter();
  @Output() onEnter = new EventEmitter();
  @Input() currentValue: string = '';
  @Input() inputError: boolean = false;
  @Input() focusOnClick: boolean = false;
  @Input() isCompareSearch: boolean = false;
  @Input() mobileModal: boolean = false;
  @Input() collapsed: boolean = false;
  @Input() autoFocus: boolean = false;

  suggestions: any[] = [];
  typeaheadLoading = false;
  typeahead$: Subject<string> = new Subject<string>();

  results$: Observable<any[]> = new Observable<any[]>();
  showDropOptions: boolean = false;
  keywordTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  recentList: any = [];

  _dropOptions!: Subscription;

  _focusEvent!: Subscription;

  constructor(
    private sst: SstStateService,
    private gService: GSearchService,
    private route: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public sstState: SstStateService,
    public device: DeviceService,
    private sstCms: SstCmsProviderService,
    private compareService: CompareService,
    private router: Router,
    private mobileSst: MobileSstStateService
  ) {
    this._dropOptions = this.sst.showDropOptions.subscribe((res) => {
      this.showDropOptions = res.keyword;
    });
    this._focusEvent = this.sst.focusSearchFieldEvent.subscribe((res) => {
      if (res.keyword && this.focusOnClick) {
        setTimeout(() => {
          this.ngSelect.focus();
        }, 500);
      }
    });

    if (device.width <= 428) this.initMobileSst();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.ngSelect) {
      return;
    }
    this.ngSelect.searchInput.nativeElement.value = this.currentValue;
  }

  ngOnInit(): void {
    this.results$ = this.typeahead$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap((term) => {
        this.typeaheadLoading = true;
        // this.searchForm.controls['keyword'].setValue(term);
      }),
      switchMap((term) =>
        this.sst.actionSearch(term ?? '').pipe(
          catchError(() => of([])), // empty list on error
          tap((res) => {
            this.typeaheadLoading = false;
          })
        )
      )
    );

    this.results$.subscribe((items) => {
      this.suggestions = this.getSuggestions(items);
    });
  }

  ngAfterViewInit(): void {
    this.recentList = this.sst.getRecentSearch().map((entry: any) => {
      if (entry.key == 'zip') {
        entry['class'] = 'fa fa-map-pin';
      }
      if (entry.key == 'address') {
        entry['class'] = 'fa fa-location-dot';
      }
      if (entry.key == 'keyword') {
        entry['class'] = '';
      }
      return entry;
    });

    this.recentList = this.filterRecentList(this.recentList);
    this.ngSelect.searchInput.nativeElement.value = this.currentValue;
    // this.ngSelect.searchInput.nativeElement.placeholder =
    //   '"Hollywood High", "soccer"';
    this.renderer.listen('body', 'click', (ev) => {
      this.showDropOptions = false;
    });

    this.setAutoFocus();
    // this.ngSelect.focus();
    // this.ngSelect.searchInput.nativeElement.select();
    // this.ngSelect.searchInput.nativeElement.focus();
  }

  setAutoFocus() {
    setTimeout(() => {
      if(this.autoFocus) {
        console.log("autofocus1")
        this.ngSelect.focus();
        this.ngSelect.searchInput.nativeElement.select();
        this.ngSelect.searchInput.nativeElement.focus();
        console.log(this.ngSelect)
        console.log('500')
      }
    }, 500);
  }

  ngAfterContentInit(): void {
    // setTimeout(() => {
    //   this.ngSelect.blur();
    //   this.showDropOptions = false;
    // }, 150);
  }

  filterRecentList(list: any[]) {
    return list.filter((item) => item.key == 'keyword');
  }

  onInputSearch(ev: any) {
    this.keywordTerm.next(ev.term);
    if (ev.term.length === 0 || !ev) {
      this.showDropOptions = true;
      return;
    }
    // hide first dropdown
    this.showDropOptions = false;
    this.handleKeywordChange(this.keywordTerm.getValue());
    this.mobileSst.keyword.next(ev.term);
  }

  shouldLimitList = {
    zip: true,
    address: true,
    school: true,
    location: true,
  };

  initMobileSst() {
    const _keyword = this.mobileSst.keyword.subscribe((res) => {
      this.currentValue = res;
      this.ngSelect.searchInput.nativeElement.value = res;
    });
  }

  getSuggestions(items: any) {
    console.log(items, 'items');
    if (!items || items.length == 0) {
      return [];
    }

    const order = ['school'];
    const suggestions: any[] = [];

    for (const key of order) {
      const shouldLimit = this.shouldLimitList[key];
      if (shouldLimit) {
        const itemsOfKey = items.filter((item) => item.source === key);
        const limit = itemsOfKey.slice(0, 5) as any;
        suggestions.push(...limit);
      }
    }

    return suggestions;
  }

  addInput(input) {
    this.selectedItem({
      item: {
        id: input.value.replace(/ /g, '-').replace(/,/g, ''),
        name: input.value,
        source: input.key,
      },
    } as any);
  }

  handleEnter() {
    this.onEnter.emit();
  }

  selectedItem($event: any, isKeyword?: boolean) {
    // if (this.mobileModal || this.isCompareSearch) {
    this.onSelectedItem.emit($event);
    // return;
    // }
    const keywordTerm = this.keywordTerm.getValue();
    if (!$event) {
      return;
    }
    if (isKeyword) {
      const keyword = keywordTerm;
      $event.source = 'keyword';
      $event.id = keyword;
    }
    if ($event.source == 'keyword') {
      this.ngSelect.searchTerm = $event.id;
      this.searchKeys();
      return;
    }
    const location = {};
    this.sst.updateLocation($event);
    this.sst.updateLocationInfo($event);
    this.gService.searchByPlaceId($event.id).subscribe((s) => {
      console.log(s);
    });
    let qParams: any = {};
    // if ($event.source == 'address') qParams.address = $event.id;
    // if ($event.source == 'school') qParams.school = $event.id;
    // if ($event.source == 'zip') qParams.zip = $event.id;
    // if (this.selectedGrades) qParams.grade = this.selectedGrades;
    // console.log(this.selectedGrades);

    if ($event.source == 'school') {
      const costCenter = $event.id;
      const schoolName = $event.name.replace(/ /g, '-').replace(/,/g, '');

      if (this.isCompareSearch) {
        let school;
        this.sstCms.actionGetSchoolById(costCenter).subscribe((res) => {
          school = res;
          this.compareService.updateCompare(school);
          this.ngSelect.searchInput.nativeElement.value = '';
          // let compareList = [
          //   ...this.compareService.compareCount$.getValue(),
          // ].join(',');
          // this.router.navigate([`compare`], {
          //   queryParams: {
          //     schools: compareList,
          //   },
          // });
        });
      } else {
        window.localStorage.setItem('school-source', 'home');
        this.route.navigate([`school`, costCenter, schoolName]);
        this.handleClose();
      }
    }
    // } else {
    //   this.route.navigate([`search`], {
    //     queryParams: qParams,
    //   });
    // }
  }

  searchKeys() {
    // this.sst.isCurrentLocation$.next(false);
    // const searchTerm = this.ngSelect.searchTerm;
    // this.sst.searchPageSettings.searchType = 'home';
    // if (searchTerm?.length == 0 || !!!searchTerm) {
    //   this.inputError = true;
    //   this.toastr.error(`Please include a keyword or location.`);
    //   return;
    // }
    // let keySearch: any = { tags: this.searchForm.controls['filter'].value };
    // if (this.selectedGrades.length > 0)
    //   keySearch['grade'] = this.selectedGrades;
    // this.inputError = false;
    // this.route.navigate([`search`], {
    //   queryParams: {
    //     tags: searchTerm,
    //     grade: this.selectedGrades,
    //   },
    // });
  }

  handleKeywordChange(ev: any) {
    this.ngSelect.searchInput.nativeElement.value = ev;
    this.onKeywordUpdate.emit(ev);
  }

  handleClose() {
    this.onclose.emit();
  }

  selectKeyword(keyword: string) {
    // if (!this.mobileModal && !this.isCompareSearch) {
    //   return;
    // }
    this.ngSelect.searchInput.nativeElement.value = keyword;
    this.onSelectedKeyword.emit(keyword);
  }

  clearField() {
    // if (!this.mobileModal && !this.isCompareSearch && this.device.width <= 428) {
    //   console.log('clear')
    //   const openModal = true;
    //   this.onClearField.emit(openModal)
    //   return;
    // }

    this.ngSelect.searchTerm = '';
    this.ngSelect.searchInput.nativeElement.value = '';
    this.ngSelect.searchInput.nativeElement.focus();
    this.handleKeywordChange('');
  }
}

<section class="d-flex flex-row flex-wrap w-100">
  <ng-container *ngFor="let num of Array(count).fill(0)">
    <ng-container [ngSwitch]="type">
      <div *ngSwitchCase="'favorite-list'" class="favorite-card col-4">
        <div class="card">
          <div class="header">
            <div class="col-6">
              <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="body">
            <div class="col-6">
              <ngx-skeleton-loader appearance="circle"></ngx-skeleton-loader>
              <ngx-skeleton-loader
                class="w-100"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
            <div class="col-6">
              <ngx-skeleton-loader appearance="circle"></ngx-skeleton-loader>
              <ngx-skeleton-loader
                class="w-100"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'school-card'" class="school-card col-12 col-md-6 col-lg-4 col-xl-3 p-2">
        <div class="card">
          <div class="header">
            <div class="img-placeholder">
              <i class="fas fa-4x fa-school"></i>
            </div>
          </div>
          <div class="body">
            <ngx-skeleton-loader
              class="w-75"
              appearance="line"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
              class="mt-4"
              appearance="line"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
            class="w-50"
            appearance="line"
          ></ngx-skeleton-loader>

      

    
          </div>


          <div class="footer">
            <div class="d-flex flex-row row">
              <div class="col-6">
                <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
              </div>

              <div class="col-6">
                <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
              </div>

            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</section>

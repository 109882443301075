import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimAddress'
})
export class TrimAddressPipe implements PipeTransform {

  transform(value: string): string {
    if (value.includes(', CA, USA')) {
      return value.replace(', CA, USA', '');
    }
    return value;
  }
}

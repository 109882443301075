import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileSstStateService {

  public keyword: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public location: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() { }
}

import { OperationsService } from 'src/app/shared/services/operations.service';
import { Component, Input, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../../services/profile.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from '../../services/translator.service';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent implements OnInit, AfterViewInit {
  points: number[] = [1, 2, 3, 4, 5,6, 7, 8, 9, 10];
  done = false;
  @Output() close = new EventEmitter();

  constructor(
    private profileService: ProfileService,
    private toast: ToastrService,
    private opServices: OperationsService,
    private dialogRef: MatDialogRef<FeedbackModalComponent>,
    private translator: TranslatorService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.translator.updateTranslate();
  }

  giveFeedback(value: any) {

    const request = {
      reqType: 'nps',
      email: this.profileService.profile.email,
      comment: '',
      rating: value,
      app: `SST APP: ${this.profileService.profile.role }`,
      language: this.profileService.profile.language,
    };
    this.opServices.sendFeedback(request).subscribe(
      (result: any) => {
        this.toast.success('Successfully submitted feedback');
        this.done = true;
        setTimeout(() => this.closeModal(), 3000)
      },
      (error: any) => {
        this.toast.error(error);
      }
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  onClose() {
    this.close.emit(true);
  }
}

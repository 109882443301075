import { PaginatedResult } from './../api/facilities/model/paginatedResult';
import { SstInfoService } from './../api/facilities/api/sstInfo.service';
import { MdlSstSchoolInfo } from './../api/facilities/model/mdlSstSchoolInfo';
import { SstCmsRole } from './../api/facilities/model/sstCmsRole';
import { Observable, Subject, BehaviorSubject, forkJoin, of } from 'rxjs';
import { SstCmsService } from './../api/facilities/api/sstCms.service';
import { Injectable } from '@angular/core';
import { MdlCmsSchoolInfo, SstChangeRequest, SstCmsWorkflow, SstInsights } from '../api/facilities';
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class SstCmsProviderService {



  homeDescription: string | null = ''
  homeBg: string | null = '';
  s3BaseUrl: string = ''

  schoolList$: Subject<PaginatedResult> = new Subject<PaginatedResult>()
  workflowPending$: Subject<Array<SstCmsWorkflow>> = new BehaviorSubject<Array<SstCmsWorkflow>>([]);
  historical$: BehaviorSubject<Array<SstCmsWorkflow>> = new BehaviorSubject<Array<SstCmsWorkflow>>([]);
  editors$: BehaviorSubject<Array<SstCmsRole>> = new BehaviorSubject<Array<SstCmsRole>>([]);
  roles$: Subject<Array<SstCmsRole>> = new Subject<Array<SstCmsRole>>();

  constructor(private sstCms:SstCmsService, private sst: SstInfoService) {

  }

  versions$: BehaviorSubject<Array<MdlCmsSchoolInfo>> = new BehaviorSubject<Array<MdlCmsSchoolInfo>>([]);
  version$: BehaviorSubject<MdlCmsSchoolInfo | null> = new BehaviorSubject<MdlCmsSchoolInfo | null>(null);
  session$: BehaviorSubject<MdlCmsSchoolInfo | null> = new BehaviorSubject<MdlCmsSchoolInfo | null>(null);
  draft$: BehaviorSubject<MdlCmsSchoolInfo | null> = new BehaviorSubject<MdlCmsSchoolInfo | null>(null);
  review$: BehaviorSubject<MdlCmsSchoolInfo | null> = new BehaviorSubject<MdlCmsSchoolInfo | null>(null);
  school$: Subject<MdlSstSchoolInfo | null> = new Subject<MdlSstSchoolInfo | null>();
  schoolCms$: Subject<MdlCmsSchoolInfo | null> = new Subject<MdlCmsSchoolInfo | null>();
  versionWorkflow$: Subject<Array<SstCmsWorkflow>> = new Subject<Array<SstCmsWorkflow>>();
  workflowPath$: Subject<Array<MdlCmsSchoolInfo>> = new Subject<Array<MdlCmsSchoolInfo>>();


  actionSetWorkflowStatus(sessionId: string, status: boolean, comments: string): Observable<any> {
    return this.sstCms.v1SstCmsWorkflowSessionIdPost(sessionId, status, comments)
  }

  actionGetVersions(costCenter: string){
    this.sstCms.v1SstCmsSchoolIdVersionsGet(costCenter).subscribe((response)=>{
      this.versions$.next(response)
    })
  }
  actionRevertVersion(id: any, version: any) {
    return this.sstCms.v1SstCmsSchoolIdRevertVersionIdGet(id, version);
  }

  actionGetVersion(costCenter: string, versionId: string){
    this.sstCms.v1SstCmsSchoolIdVersionsVersionIdGet(costCenter, versionId).subscribe((response)=>{
      this.version$.next(response)
    })
  }

  actionGetSession(costCenter: string, sessionId: string){
    return this.sstCms.v1SstCmsSchoolIdSessionsSessionIdGet(costCenter, sessionId);
  }

  actionGetDraft(costCenter: string){
    this.sstCms.v1SstCmsSchoolIdDraftGet(costCenter).subscribe((response)=>{
      this.draft$.next(response)
    })
  }

  actionGetReview(costCenter: string){
    this.sstCms.v1SstCmsSchoolIdReviewGet(costCenter).subscribe((response)=>{
      this.review$.next(response)
    })
  }

  actionGetSchool(costCenter: string){
      this.sst.v1SstSchoolIdGet(costCenter).subscribe((response)=>{
        this.school$.next(response)
      })
  }

  actionGetSchoolById(costCenter: string){
    return this.sst.v1SstSchoolIdGet(costCenter)
}

  actionGetCmsSchool(costCenter: string){
    this.sstCms.v1SstCmsSchoolIdGet(costCenter).subscribe((response)=>{
      this.schoolCms$.next(response)
    })
}


  actionGetEditors(costCenter: string){
    this.sstCms.v1SstCmsEditorsGet(costCenter).subscribe((response)=>{
      this.editors$.next(response)
    })
  }

  actionGetHistorical(state: string){
    this.sstCms.v1SstCmsWorkflowStateGet(state).subscribe((response)=>{
      response = _.orderBy(response, ['modified'], 'desc')
      this.historical$.next(response)
    })
  }
  async actionGetSchools(page: string, pageSize: string, cos: string, costCenter:string, schoolName: string, state: string, orderBy: string){
    this.sstCms.v1SstCmsSchoolsGet(page, pageSize,'0',cos,costCenter,schoolName,state,orderBy).subscribe((response)=>{
      this.schoolList$.next(response)
    })
  }

  actionGetRoles(costCenter: string) {
    this.sstCms.v1SstCmsEditorsGet(costCenter).subscribe((response)=>{
      this.editors$.next(response)
    })
  }

  actionUserRoles() {
    this.sstCms.v1SstCmsRolesGet().subscribe((response)=>{
      this.roles$.next(response)
    })
  }

  actionGetWorkflow(){
    this.sstCms.v1SstCmsWorkflowGet().subscribe((response)=>{
      this.workflowPending$.next(response)
    })
  }
  actionGetWorkflowByCostCenter(costCenter: string):Observable<Array<MdlCmsSchoolInfo>>{
    return this.sstCms.v1SstCmsSchoolIdWorkflowsGet(costCenter)
  }
  actionGetVersionWorkflow(costCenter: string , versionId: string){
    this.sstCms.v1SstCmsSchoolIdVersionsVersionIdWorkflowGet(costCenter, versionId).subscribe((response)=>{
      this.versionWorkflow$.next(response);
    })
  }
  actionGetWorkflowsPath(costCenter: string , path: string){
    this.sstCms.v1SstCmsSchoolIdPathWorkflowsGet(costCenter, path).subscribe((response)=>{
      this.workflowPath$.next(response);
    })
  }
  actionGetWorkflowsPathCombined(costCenter: string, path: string, state1: string, state2: string)
  {
    let reviewRequest = this.sstCms.v1SstCmsSchoolIdPathWorkflowsGet(costCenter, path,state1);
    let draftRequest = this.sstCms.v1SstCmsSchoolIdPathWorkflowsGet(costCenter, path, state2);

    forkJoin({
      draft:draftRequest,
      review: reviewRequest
    }).subscribe((response)=>{
      let total = [...response.draft,...response.review]
      this.workflowPath$.next(total);
    })
  }

  actionGetWorkflowsPathState(costCenter: string , path: string, state: string){
    return this.sstCms.v1SstCmsSchoolIdPathWorkflowsGet(costCenter, path,state)
  }
  actionCreateDraft(costCenter: string , change: SstChangeRequest, isDraft:boolean): Observable<any> {
    return this.sstCms.v1SstCmsSchoolIdReviewPost(costCenter,isDraft,change)
  }
  actionUpdateDraft(costCenter: string , change: SstChangeRequest): Observable<any> {
    return this.sstCms.v1SstCmsSchoolIdReviewPut(costCenter,change)
  }
  getWorkflowSteps(costCenter: string, sessionId: string): Observable<SstCmsWorkflow[]>{
    return this.sstCms.v1SstCmsSchoolIdSessionSessionIdWorkflowsGet(costCenter,sessionId)
  }
  actionGetInsights(startDate: string, endDate: string) : Observable<SstInsights>{
    return this.sstCms.v1SstCmsSchoolCalculateGet(undefined,startDate, endDate)
  }
}


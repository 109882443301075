import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'https',
})
export class HttpsPipe implements PipeTransform {
  transform(value: string): string {
    if (value && !/^https?:\/\//i.test(value)) {
      return 'https://' + value;
    }
    return value;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-modal',
  templateUrl: './mobile-modal.component.html',
  styleUrls: ['./mobile-modal.component.scss']
})
export class MobileModalComponent {

  @Input() show: boolean = false;
  @Input() closeEvent: any;
  @Output() onclose = new EventEmitter;


  handleClose() {
    this.onclose.emit({type: this.closeEvent})
  }
}

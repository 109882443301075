import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./sst/sst.module').then((m) => m.SstModule),
  },
  {
    path: 'admin',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },

  // Not found
  { path: 'admin/**', redirectTo: 'admin'},
  { path: '**', redirectTo: ''}
];

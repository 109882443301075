import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private deviceWidthSubject = new BehaviorSubject<number>(window.innerWidth);
  deviceWidth$ = this.deviceWidthSubject.asObservable();

  constructor() {
    window.addEventListener('resize', () => {
      this.deviceWidthSubject.next(window.innerWidth);
    });
  }

  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  isPortrait(): boolean {
    return window.innerHeight > window.innerWidth;
  }

  get type(): string {
    const width = this.deviceWidthSubject.getValue();
    if (width <= 320) {
      return 'xs-mobile'
    }
    else if (width <= 375 ) {
      return 'sm-mobile'
    }
    else if (width <= 428) {
      return 'mobile';
    } else if (width <= 768) {
      return 'tablet';
    } else {
      return 'web';
    }
  }

  get width(): number {
    return this.deviceWidthSubject.getValue();
  }

}

import {
  Component,
  Input,
  Type,
  ViewContainerRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-host-component',
  templateUrl: './host-component.component.html',
  styleUrls: ['./host-component.component.scss'],
})
export class HostComponentComponent {
  @ViewChild('target', {
    read: ViewContainerRef,
  })
  target!: ViewContainerRef;
  constructor(private viewRef: ViewContainerRef) {}

  @Input() componentType!: Type<any>;

  ngOnInit() {
    const component = this.viewRef.createComponent(this.componentType);
  }
}

<div class="item user-block" *ngIf="userBlockIsVisible() && user.id">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status">
            <app-avatar [id]="user.id" [role]="user.role" [size]="getIsCollapsed() ? 36 : 60"></app-avatar>
            <div class="circle bg-success circle-lg"></div>
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name text-muted">{{user.firstName}} {{user.lastName}}</span>
        <span class="user-block-role"><span class="badge badge-{{getBadge(rs.role)}}">{{rs.role}}</span></span>
    </div>
</div>

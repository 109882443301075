import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'htmlTranslate'
})
export class HtmlTranslatePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string): SafeHtml {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = doc.body.children;

    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('v3-translate', '');
    }

    // Loop through child nodes of the body and wrap any text nodes in a span
    const childNodes = Array.from(doc.body.childNodes);
    for (let i = 0; i < childNodes.length; i++) {
      const node = childNodes[i];
      if (node.nodeType === Node.TEXT_NODE && node?.textContent?.trim() !== '') {
        const span = doc.createElement('span');
        span.setAttribute('v3-translate', '');
        span.textContent = node.textContent;
        node.replaceWith(span);
      }
    }

    const sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
    return sanitizedHtml;
  }
}
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ColorsService } from './colors/colors.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { HostComponentComponent } from './components/host-component/host-component.component';
import { DynamicModule } from 'ng-dynamic-component';
import { ConsoleLogPipe } from '../routes/admin/pipes/console-log.pipe';
import { TrimAddressPipe } from './pipes/trim-address.pipe';
import { LanguageModalComponent } from './components/language-modal/language-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { ProfileService } from './services/profile.service';
import { MatCardModule } from '@angular/material/card';
import { ToastrModule } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImageFallbackDirective } from '../routes/admin/directives/image-fallback.directive';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BulletListPipe } from './pipes/bullet-list.pipe';
import { SectionTypeDirective } from './section-type.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { HtmlTranslatePipe } from './pipes/html-translate.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TruncateListPipe } from './pipes/truncate-list.pipe';
import { SchoolDataToArrayPipe } from './pipes/school-data-to-array.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverComponent } from './components/popover/popover.component';
import { KeywordAdvSearchComponent } from './components/keyword-adv-search/keyword-adv-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchDropdownComponent } from '../routes/sst/components/search-dropdown/search-dropdown.component';
import { LocationAdvSearchComponent } from './components/location-adv-search/location-adv-search.component';
import { TkToUtkPipe } from './pipes/tk-to-utk.pipe';
import { SideFabComponent } from '../routes/sst/components/side-fab/side-fab.component';
import { ButtonComponent } from './components/button/button.component';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { HttpsPipe } from './pipes/https.pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { MobileModalComponent } from './components/mobile-modal/mobile-modal.component';
import { ShortenAddressPipe } from './pipes/shorten-address.pipe';

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    DynamicModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSkeletonLoaderModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    NgSelectModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule
  ],
  providers: [ColorsService, ProfileService],
  declarations: [
    HostComponentComponent,
    ConsoleLogPipe,
    TrimAddressPipe,
    LanguageModalComponent,
    FeedbackModalComponent,
    ImageFallbackDirective,
    AvatarComponent,
    BulletListPipe,
    SectionTypeDirective,
    SkeletonLoaderComponent,
    HtmlTranslatePipe,
    SpinnerComponent,
    TruncateListPipe,
    SchoolDataToArrayPipe,
    PopoverComponent,
    KeywordAdvSearchComponent,
    SearchDropdownComponent,
    KeywordAdvSearchComponent,
    LocationAdvSearchComponent,
    TkToUtkPipe,
    SideFabComponent,
    ButtonComponent,
    HttpsPipe,
    ImageLoaderComponent,
    MobileModalComponent,
    ShortenAddressPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TabsModule,
    MatDialogModule,
    HostComponentComponent,
    DynamicModule,
    ConsoleLogPipe,
    TrimAddressPipe,
    MatRadioModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    ImageFallbackDirective,
    AvatarComponent,
    BulletListPipe,
    SectionTypeDirective,
    NgxSkeletonLoaderModule,
    SkeletonLoaderComponent,
    HtmlTranslatePipe,
    SpinnerComponent,
    TruncateListPipe,
    SchoolDataToArrayPipe,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    PopoverComponent,
    NgSelectModule,
    SearchDropdownComponent,
    KeywordAdvSearchComponent,
    LocationAdvSearchComponent,
    TkToUtkPipe,
    SideFabComponent,
    ButtonComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    HttpsPipe,
    MatSlideToggleModule,
    ImageLoaderComponent,
    MobileModalComponent,
    ShortenAddressPipe
  ],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}

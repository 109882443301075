import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenAddress',
})
export class ShortenAddressPipe implements PipeTransform {
  transform(address: string): string {
    if (!address) {
      return '';
    }

    // Find the index of the first comma in the address
    const commaIndex = address.indexOf(',');

    if (commaIndex === -1) {
      // If there's no comma in the address, return the original address
      return address;
    } else {
      // Extract the portion of the address up to the first comma and append ellipsis
      return address.substring(0, commaIndex) + '...';
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SstCmsRole } from 'src/app/shared/api/facilities';
import { SstCmsProviderService } from 'src/app/shared/services/sst-cms-provider.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  userRole: string = '';

  role$: Subject<string> = new Subject<string>();


  _roles = this.cms.roles$.subscribe((roles) => {
    const admin = roles.find(rl => { return rl.userType === 'Admin'});
    const approver = roles.find(rl => { return rl.userType === 'Approver'});
    const editor = roles.find(rl => { return rl.userType === 'Editor'});

    if(admin) this.userRole = 'Admin'
    else if(approver) this.userRole = 'Approver'
    else this.userRole = 'Editor'

    console.log('userRole:', this.userRole)
    this.role$.next(this.userRole);
  });
  isParent: boolean = false;

  constructor(
    private cms: SstCmsProviderService
  ) { }


  get role() {
    return this.userRole
  }

  roleAccess(roles: string[]) {
    return roles.includes(this.userRole)
  }
}

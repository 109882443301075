import { AppConfigService } from 'src/app/shared/services/app-config.service';
import { RoleService } from './../../../routes/admin/services/role.service';
import { SstCmsProviderService } from 'src/app/shared/services/sst-cms-provider.service';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { UserblockService } from './userblock.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { SettingsService } from 'src/app/core/settings/settings.service';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss'],
})
export class UserblockComponent implements OnInit {
  user: any;
  role!: string;


  constructor(
    public userblockService: UserblockService,
    private profileService: ProfileService,
    public rs: RoleService,
    private sst: SstCmsProviderService,
    private appConfig: AppConfigService,
    private settings: SettingsService
  ) {
    // this.user = {
    //   picture: 'assets/role-avatars/teacher.svg',
    // };
  }

  ngOnInit() {
    this.profileService.getProfile().subscribe((res) => {
      this.user = res;
      console.log('user', this.user)
    });
    this.sst.actionUserRoles();
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

  getIsCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed')
  }

  getBadge(role: string) {
    return role == 'Admin'
      ? 'info'
      : role == 'Editor'
      ? 'success'
      : 'primary';
  }

  getRole(role: string) {
    return role == 'Admin'
    ? 'System Admin'
    : role == 'Editor'
    ? 'Content Editor'
    : 'Content Approver';
  }
}

import { AppConfig } from '../models/configuration';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  appConfig: AppConfig | null = null;
  constructor() {}
  loadAppConfig() {
    return new Promise<void>((resolve, reject) => {
      fetch(this.getConfigUrl())
        .then((response) => response.json())
        .then((config) => {
          this.appConfig = config as any;
          resolve();
        });
    });
  }

  getConfigUrl() {
    const host = window.location.host;
    let uri = '/configurations/mobility';
    if (host.indexOf('localhost') >= 0) {
        // uri = 'https://localhost:44350' + uri;
      uri = 'https://servicesstg.lausd.net/authentication' + uri;
    }
    if (
      host == 'exploredev.lausd.net' ||
      host == 'exploredev.lausd.org' ||
      host == 'd1u1eu6ovytbfi.cloudfront.net'
    ) {
      uri = 'https://servicesdev.lausd.net/authentication' + uri;
    }
    if (host == 'exploreqa.lausd.net') {
      uri = 'https://servicesqa.lausd.net/authentication' + uri;
    }
    if (
      host == 'explorestg.lausd.net' ||
      host == 'explorestg.lausd.org' ||
      host == 'dxitvova7djgu.cloudfront.net'
    ) {
      uri = 'https://servicesstg.lausd.net/authentication' + uri;
    }
    if (
      host == 'exploretst.lausd.net' ||
      host == 'exploretst.lausd.org' ||
      host == 'd3ouhnhf3h10mb.cloudfront.net'
    ) {
      uri = 'https://servicestst.lausd.net/authentication' + uri;
    }
    if (
      host == 'exploreprod.lausd.net' ||
      host == 'explore.lausd.org' ||
      host =='dujlzpku7v3pa.cloudfront.net'
    ) {
      uri = 'https://services.lausd.net/authentication' + uri;
    }
    return uri;
  }
}

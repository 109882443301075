<div (click)="$event.stopImmediatePropagation()" [ngClass]="{'mobile': mobileView}" class="drop-options">
  <ul *ngIf="showCurrentLocation">
    <li>
      <span *ngIf="!isLoading['currLocation']"><i class="fa fa-fw fa-location-dot"></i></span>
      <span *ngIf="isLoading['currLocation']" class="mr-2">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </span>
      <span *ngIf="!isLoading['currLocation'] && !disableCurrLocation" class="local-address" (click)="$event.stopImmediatePropagation();loadLocation()"
        v3-translate>Current Location</span
      >
      <span *ngIf="disableCurrLocation || isLoading['currLocation']" class="local-address disabled" v3-translate>
        Current Location
      </span>
    </li>
  </ul>
  <div class="recent-list" [ngClass]="{'mt-3': showCurrentLocation}">
    <div class="label" v3-translate>Search History</div>
    <ul>
      <li *ngFor="let entry of recentList" (click)="$event.stopImmediatePropagation(); addInput(entry)">
        <span>
          <i [ngClass]="'far fa-fw fa-clock'"></i>
        </span>
        <span>{{ entry.value }}</span>
      </li>
      <li *ngIf="recentList.length == 0" class="pl-0" v3-translate>
        No {{isLocation ? 'location' : 'keyword'}} history
      </li>
    </ul>
  </div>
</div>

import { AppConfigService } from 'src/app/shared/services/app-config.service';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { StudentService } from 'src/app/routes/sst/services/student.service';
import { ProfileService } from '../../services/profile.service';
import { SstStateService } from '../../services/sst-state.service';
import { AvatarService } from '../../services/avatar.service';
import { CdnService } from '../../services/cdn.service';

export interface Fallback {
  role: string;
  img: string;
}

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() role: string = '';
  @Input() id: string = '';
  @Input() size: number = 48;
  @Input() defaultImg: string = '';

  avatarSrc: string = '/assets/img/icons/default.png';
  errorSrc: string = '/assets/img/icons/default.png';

  hasInputs: boolean = !!this.id && !!this.role;

  constructor(
    private studentService: StudentService,
    private profileService: ProfileService,
    private sst: SstStateService,
    private avatar: AvatarService,
    private cdn: CdnService,
    private appConfig: AppConfigService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.validateRoles();
    this.errorSrc = this.getRoleImg();
  }

  ngOnInit(): void {
    if (!this.hasInputs) {
      this.avatarSrc = this.defaultImg;
      this.addDefaultImg();
    } else {
      this.validateRoles();
    }
    this.avatar.refresh$.subscribe(()=>{
      this.validateRoles();
    })
  }

  addDefaultImg() {
    if (this.defaultImg && this.sst.isLogged$.getValue()) {
      this.errorSrc = this.defaultImg;
    }
  }

  getRoleImg() {
    return (
      this.avatar.fallbacks.find((f) => f.role == this.role)?.img ||
      '/assets/img/icons/default.png'
    );
  }

  validateImg(src: string | null | undefined) {

    return src ?? this.getRoleImg() ?? this.defaultImg;
  }

  validateRoles() {
    if (this.role == 'Student' && this.id != null) {
      this.studentService.getPicture(this.id).subscribe(
        (res) => {
          this.avatarSrc = this.validateImg(res.img);
        },
        (err) => {
          this.errorSrc = this.getRoleImg();
          this.addDefaultImg();
        }
      );
    } else {
      if (this.id) {
        this.profileService.getProfileById(this.id).subscribe(
          (res) => {
            this.avatarSrc = this.appConfig.appConfig?.environment.lausdConfig+'/profiles/avatars/'+ this.validateImg(res.avatar);
          },
          (err) => {
            this.errorSrc =
              this.avatar.fallbacks.find((f) => f.role == this.role)?.img ||
              '/assets/img/icons/default.png';
            this.addDefaultImg();
          }
        );
      } else {
        this.errorSrc =
        this.avatar.fallbacks.find((f) => f.role == this.role)?.img ||
        '/assets/img/icons/default.png';
        this.addDefaultImg();
      }
    }

    this.addDefaultImg();
  }
}

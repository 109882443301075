import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { SstCmsProviderService } from './sst-cms-provider.service';
import { TranslatorService } from './translator.service';
import { BehaviorSubject, filter, first } from 'rxjs';
import swal from 'sweetalert';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  currTour$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  tourStatusKey = 'tourStatus';

  get isActive() {
    return this.shepherdService.isActive;
  }

  get isTourOn() {
    return localStorage.getItem(this.tourStatusKey) === 'on';
  }

  constructor(
    private shepherdService: ShepherdService,
    private sstCms: SstCmsProviderService,
    private translator: TranslatorService,
    private router: Router,
    private toastr: ToastrService
  ) {
    if (!!localStorage.getItem(this.tourStatusKey) == false) {
      localStorage.setItem(this.tourStatusKey, 'on');
    }

    const _router = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((res) => {
        // Reset currTour
        this.currTour$.next([]);
      });
  }

  startTour(tourSteps: any[], isManualStart?: boolean) {
    // First, cache the current tour
    if (tourSteps !== this.shortCutTourSteps) {
      this.currTour$.next(tourSteps);
    }
    if (this.isActive) {
      return;
    }

    if(!isManualStart) { // Only allow manual start
      return
    }

    if (isManualStart) {
      // Check if tour steps
      if (tourSteps.length === 0) {
        this.toastr.warning('No tour available for this page');
        return;
      }
      this.configureShepherd();
      this.shepherdService.addSteps(tourSteps);
      this.shepherdService.start();
      setTimeout(() => {
        this.translator.updateTranslate();
      }, 1000);

      return;
    }

    // Then, check if tour is on or not before starting
    //
    if (!this.isTourOn) {
      return;
    }

    setTimeout(() => {
      // Check if element exists before starting
      //
      let firstElementId = tourSteps[0].attachTo.element as string;
      firstElementId = firstElementId.replace('#', '');
      const firstElement = document.getElementById(firstElementId);
      if (!firstElement) {
        return;
      }

      this.configureShepherd();
      this.shepherdService.addSteps(tourSteps);
      this.shepherdService.start();
      setTimeout(() => {
        this.translator.updateTranslate();
      }, 1000);
    }, 3000);

    // setTimeout(() => {

    // }, 3000);
  }

  private configureShepherd() {
    this.shepherdService.defaultStepOptions = {
      scrollTo: false,
    };
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
  }

  public getTextHtml(text: string) {
    const html = `<span v3-translate>${text}</span>`;
    return html;
  }

  onNext() {
    this.shepherdService.next();
    this.translator.updateTranslate();
  }

  async onCancelTour() {
    const shouldCancel = await this.confirmEndTour();
    if (!shouldCancel) {
      return;
    }
    this.shepherdService.cancel();
    this.startShortCutTour();
    localStorage.setItem(this.tourStatusKey, 'off');
  }

  getButtons(isLastStep?: boolean) {
    const buttons = [
      {
        text: this.getTextHtml(`End Tour`),
        action: () => {
          this.onCancelTour();
        },
      },
      {
        text: this.getTextHtml(`Next`),
        action: () => {
          this.onNext();
        },
      },
    ];

    const lastStepButtons = [
      {
        text: this.getTextHtml(`End`),
        action: () => {
          this.shepherdService.complete();
        },
      },
    ];

    return !isLastStep ? buttons : lastStepButtons;
  }

  async confirmEndTour() {
    return swal({
      title: `End Tour`,
      icon: 'warning',
      text: 'This concludes the guided tour of the L.A. Unified School Explorer.',
      buttons: {
        cancel: {
          value: false,
          visible: true,
          closeModal: true,
        },
        confirmEnd: {
          text: 'End Tour',
          value: true,
          visible: true,
          closeModal: true,
          className: 'secondary-btn',
        },
      },
    }).then((res) => {
      return res;
    });
  }

  startShortCutTour() {
    this.startTour(this.shortCutTourSteps, true);
  }

  shortCutTourSteps = [
    {
      id: 'example-step-1',
      text: this.getTextHtml(
        `You can always turn the help tips back on by clicking here.`
      ),
      // Attach options for Step 1
      attachTo: {
        element: '#tour-shortcut',
        on: 'left',
      },
      // Other Step 1 options and buttons
      classes: 'tour-element tour-shortcut',
      buttons: [
        {
          text: this.getTextHtml(`Ok`),
          action: () => {
            this.shepherdService.complete();
          },
        },
      ],
    },
  ];
}

<div
  id="adv-search-location"
  [ngClass]="{ 'input-error': inputError['address'] }"
>
  <div
    class="location-mobile search-select"
    (click)="$event.stopImmediatePropagation()"
  >
    <!-- <span class="ng-select-placeholder">"Art, Magnet, Scoccer" - "Santa Monica" - "900249"</span> -->
    <span class="ng-select-title" v3-translate>Search location or address</span>

    <ng-select
      #ngSelect
      [items]="suggestions"
      bindLabel="name"
      bindValue="id"
      groupBy="source"
      class=".search-input"
      [typeahead]="typeahead$"
      [isOpen]="!showDropOptions && ngSelect.focused"
      (keydown.enter)="handleEnter()"
      [minTermLength]="3"
      (search)="onInputSearch($event)"
      (focus)="
        sstState.updateDropOptions('location', true); showDropOptions = true
      "
      
      (clear)="ngSelect.blur()"
      (change)="
        ngSelect.searchTerm = $event.name;
        ngSelect.blur();
        handleLocationChange($event);
        handleClose();
      "
      [clearable]="false"
    >
      <ng-template ng-label-tmp let-item="item">
        <!-- <b>{{ item.name }}</b> is cool -->
      </ng-template>
      <ng-template ng-optgroup-tmp let-item="item" let-index="index">
        <div class="dropdown-group-title" [ngClass]="{ 'mt-3': index > 0 }">
          {{
            item.source == "zip"
              ? "Zip Code"
              : (item.source || "Unnamed group" | titlecase)
          }}
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-i="index">
        <button class="dropdown-item" [ngClass]="{'drop-option-hover': i == 1}">
          <span>
            <i
              class="fa mr-2"
              [ngClass]="{
                'fa-location-dot': item.source == 'address',
                'fa-school': item.source == 'school',
                'fa-map-pin': item.source == 'zip',
                'fa-city': item.source == 'location'
              }"
            ></i>
          </span>
          {{ item.name }}
        </button>
      </ng-template>
    </ng-select>

    <div
      *ngIf="this.ngSelect.searchInput.nativeElement.value.length > 0"
      class="close-btn"
      (click)="clearField()"
    >
      <i class="fas fa-times-circle"></i>
    </div>

    <app-search-dropdown
      #optsDropdown
      *ngIf="showDropOptions"
      [recentList]="recentList"
      (handleSelectedItem)="
        handleLocationChange($event);
        sstState.updateDropOptions('location', false);
        this.handleClose()
      "
      (handleCurrentLocation)="selectedItem($event); handleClose()"
      (handleCurrentLocationError)="inputError['address'] = $event"
      [isLocation]="true"
    ></app-search-dropdown>
  </div>
</div>

<div id="mobile-modal" class="mobile-modal">
  <ng-container>
    <div class="mobile form-wrapper">
      <div class="home-page-mobile search-wrapper w-100">
        <div class="d-flex flex-row justify-content-between mt-3 mb-2">
          <a class="link link--goback" (click)="handleClose()" v3-translate
            ><i class="ml-1 fa-solid fa-xmark-large"></i></a
          >
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </ng-container>
</div>

<div class="container mb-0 form-group p-3">
    <label v3-translate class="text-bold">Feedback</label>

    <div class="d-flex">
      <span
      class="close-modal-btn position-absolute"
      style="top: 1.25rem"
      (click)="closeModal()"
      ><i class="fas fa-times"></i
    ></span>
    </div>
    
    <div class="flex-container feedback-container" *ngIf="!done">
        <div class="feedback-item">
          <div class="d-flex  inner-item flex-column justify-content-center align-items-center h-100">
            <div class="div-center">
              <h5 class="text-color" v3-translate>How satisfied are you with your online experience on the</h5>
            </div>
            <div class="div-center">
              <h4 class="text-color" v3-translate>L.A. Unified School Explorer website?</h4>
            </div>
            <div class="mt-3 mx-3 mb-3 d-flex justify-content-between align-items-center w-100">
              <ng-container *ngFor="let point of points; index as idx">
                <div (click)="giveFeedback(idx)" class="point">{{point}}</div>
              </ng-container>
            </div>
            <div class="mx-3 d-flex justify-content-between align-items-center w-100">
              <div class="div-center">
                <h6 class="text-color" v3-translate>Not satisfied</h6>
              </div>
              <div class="div-center">
                <h6 class="text-color" v3-translate>Very satisfied</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="flex-container feedback-container" *ngIf="done">
        <div class="feedback-item">
          <div class="d-flex  inner-item flex-column justify-content-center align-items-center h-100">
            <div class="div-center">
              <h3 class="text-color" v3-translate>Thank you</h3>
            </div>
            <div class="div-center">
              <h5 class="text-color" v3-translate>We appreciate your feedback</h5>
            </div>
          </div>
        </div>
      </div>
</div>

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, map } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { LanguageModalComponent } from 'src/app/shared/components/language-modal/language-modal.component';
import { AppConfigService } from 'src/app/shared/services/app-config.service';
import {
  Profile,
  ProfileService,
} from 'src/app/shared/services/profile.service';
import { FeedbackModalComponent } from 'src/app/shared/components/feedback-modal/feedback-modal.component';
import { TranslatorService } from 'src/app/shared/services/translator.service';
import { TourService } from 'src/app/shared/services/tour.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-side-fab',
  templateUrl: './side-fab.component.html',
  styleUrls: ['./side-fab.component.scss'],
})
export class SideFabComponent implements OnInit {
  profile!: Profile;

  isCms: boolean = false;
  isResultsPage: boolean = false;

  constructor(
    private dialog: MatDialog,
    private translator: TranslatorService,
    private tourService: TourService,
    public device: DeviceService,
    private router: Router
  ) {

    this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event) => {
      let ev = event as any;
      const url = ev.url as string;
      this.isCms = url.includes("/admin");
      this.isResultsPage = url.includes("/search")
    });
  }

  ngOnInit(): void {}

  openModal() {
    // this.profileService.getProfile().subscribe(res => {
    //   console.log(res)
    // })
    this.dialog
      .open(LanguageModalComponent, {
        width: '60%',
        data: this.translator.currentLng,
        panelClass: 'modal-index'
      })
      .afterClosed()
      .subscribe((newLng) => {
        if (newLng && newLng != this.translator.currentLng) {
          this.translator.updateLng(newLng)
        }
      });
  }

  feedback() {
    const isMobile = this.device.width <= 428;
    this.dialog
      .open(FeedbackModalComponent, {
        panelClass: isMobile ? 'modal-index' : '',
        width: isMobile ? '95%' : '60%',
        data: [],
      })
      .afterClosed()
      .subscribe((feedback) => {});
  }

  startTour() {
    const tourSteps = this.tourService.currTour$.getValue();
    this.tourService.startTour(tourSteps, true);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit {

  languages = [
    { slug: 'english', value: 'en', lang: 'English' },
    { slug: 'spanish', value: 'es', lang: 'Español' },
    { slug: 'italian', value: 'it', lang: 'Italiano' },
    { slug: 'persian', value: 'fa', lang: 'فارسی' },
    { slug: 'french', value: 'fr', lang: 'French' },
    { slug: 'korean', value: 'ko', lang: '한국어' },
    { slug: 'armenian', value: 'hy', lang: 'Հայերեն' },
    { slug: 'russian', value: 'ru', lang: 'Рyсский' },
    { slug: 'tagalog', value: 'tl', lang: 'Wikang Tagalog' },
    { slug: 'vietnamese', value: 'vi', lang: 'Tiếng Việt' },
    { slug: 'chinese', value: 'zh', lang: '中文' },
    { slug: 'japanese', value: 'ja', lang: '日本' },
    { slug: 'portuguese', value: 'pt', lang: 'Português' },
    { slug: 'arabic', value: 'ar', lang: 'عربي' },
  ]

  language = new FormControl('en');

  currLng: string = 'en';


  constructor(
    public dialogRef: MatDialogRef<LanguageModalComponent>,
    private oauthService:OAuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.language.setValue(this.data);
    this.disableForm();
  }

  save() {
    this.oauthService.customQueryParams!['lng'] = this.language.value!;
    window.localStorage['lng'] = this.language.value;
    this.dialogRef.close(this.language.value)
  }

  cancel() {
    this.dialogRef.close();
  }

  disableForm() {
    console.log(this.data)
    this.currLng = this.data;
    if(this.currLng == 'en') {
      return
    }
    this.language.disable();
  }

  getLng(value: string) {
    return this.languages.find(l => l.value == value)?.lang
  }

  refresh() {
    window.localStorage.removeItem('lng');
    location.reload();
  }

}

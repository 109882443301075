/**
 * Lausd.Facilities.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AnyFunction } from "ng-dynamic-component";


export interface SstCmsWorkflow {
    id?: string;
    state?: string;
    modified?: string;
    modifiedBy?: string;
    description?: string;
    sessionId?: string;
    modifiedPath?: string;
    comments?: string;
    isApproved?: boolean;
    schoolName?: string;
    school?: any;
    session?: any;
    document?: any;
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'schoolDataToArray'
})
export class SchoolDataToArrayPipe implements PipeTransform {
  transform(value: string): string[] {
    if (!value) {
      return [];
    }

    return value.split('|').map(program => program.trim());
  }
}

import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Fallback } from '../components/avatar/avatar.component';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  private studentAvatar = "/assets/img/icons/student.svg";
  private parentAvatar = "/assets/img/icons/parent.svg";
  // private teacherAvatar = "/assets/img/icons/teacher.svg";
  // private principalAvatar = "/assets/img/icons/principal.svg";
  private adminAvatar = "/assets/img/icons/admin.svg";
  // private counselorAvatar = "/assets/img/icons/counselor.svg";

  refresh$: Subject<Boolean> = new Subject<Boolean>();


  public fallbacks: Fallback[] = [
    {
      role: 'Student',
      img: this.studentAvatar
    },
    {
      role: 'Parent',
      img: this.parentAvatar
    },
    {
      role: 'Administrator',
      img: this.adminAvatar
    },
    {
      role: 'Admin',
      img: this.adminAvatar
    },
    {
      role: 'Teacher',
      img: this.adminAvatar
    },
  ]


  constructor() {
    
   }
}

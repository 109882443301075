import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';
@Directive({
  selector: '[data-section-type]'
})
export class SectionTypeDirective implements OnInit {
  @Input('data-section-type') sectionType!: string;
  constructor(
    private elementRef: ElementRef, private renderer: Renderer2
  ) { }


  ngOnInit(): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'data-section-type', this.sectionType);
  }

}

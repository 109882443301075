import { SstService } from 'src/app/shared/api/facilities';
import { SpellCheckerModule } from 'ngx-spellchecker';
import { RsiService } from './../api/facilities/api/rsi.service';
import { MdlSstSchoolInfo } from './../api/facilities/model/mdlSstSchoolInfo';
import { CompareService } from 'src/app/shared/services/compare.service';
import { SstInfoService } from './../api/facilities/api/sstInfo.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GSearchService } from './g-search.service';
import { SstSession } from '../api/facilities/model/sstSession';
import { SchoolService } from '../api/facilities/api/school.service';

import {
  forkJoin,
  Observable,
  Subject,
  BehaviorSubject,
  of,
  Observer,
  switchMap,
} from 'rxjs';
import { Injectable, QueryList } from '@angular/core';
import { KeySearchResult } from '../models/search-result';
import { AppConfigService } from './app-config.service';
import { EsRow } from '../models/es';
import { SearchPageSettings } from '../models/SearchPageSettings';
import * as _ from 'lodash';
import { catchError, map, startWith } from 'rxjs/operators';
import { RsiSettings } from '../models/RsiSettings';
import { Router } from '@angular/router';
import { SstFavoritesService } from './sst-favorites.service';
import { ADDRCONFIG } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class SstStateService {
  sstSession: SstSession | null = null;
  schoolSearchResult$: BehaviorSubject<MdlSstSchoolInfo[] | null> =
    new BehaviorSubject<MdlSstSchoolInfo[] | null>(null);
  sideSearchResult$: BehaviorSubject<MdlSstSchoolInfo[] | null> =
    new BehaviorSubject<MdlSstSchoolInfo[] | null>(null);
  searchKeywords$: Subject<String> = new Subject<String>();
  searchPageSettings: SearchPageSettings = {
    addressName: '',
    address: '',
    distance: '',
    keywords: '',
    school: '',
    zip: '',
    sort: '',
    lat: 0,
    lon: 0,
    grades: [],
    filters: [],
    pageSize: 8,
    pageTotal: 8,
    pageNumber: 0,
    searchType: '',
  };
  sideSearchSettings: SearchPageSettings = {
    addressName: '',
    address: '',
    distance: '',
    keywords: '',
    school: '',
    zip: '',
    sort: '',
    lat: 0,
    lon: 0,
    grades: [],
    filters: [],
    pageSize: 16,
    pageTotal: 16,
    pageNumber: 0,
    searchType: 'side',
  };
  rsiSettings: RsiSettings = {
    streetNumber: null,
    navDirection: null,
    streetName: null,
    streetType: null,
    city: null,
    zipCode: null,
    grade: null,
  };
  selectedLocation$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedLocationInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedSchool$: BehaviorSubject<MdlSstSchoolInfo> =
    new BehaviorSubject<MdlSstSchoolInfo>({});
  searchPageSettingsChanged$: BehaviorSubject<SearchPageSettings> =
    new BehaviorSubject<SearchPageSettings>(this.searchPageSettings);

  sideSearchSettingsChanged$: BehaviorSubject<SearchPageSettings> =
    new BehaviorSubject<SearchPageSettings>(this.sideSearchSettings);
  isLogged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLogged: boolean = false;
  username$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  schoolDetailsInfo$: Subject<any> = new Subject<any>();
  newQuery: boolean = true;
  pagination: boolean = false;

  lastSearchUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  isCurrentLocation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  showDropOptions: BehaviorSubject<DropOptions> =
    new BehaviorSubject<DropOptions>({ keyword: false, location: false });
  showHomeDropOptions: BehaviorSubject<HomeDropOptions> =
    new BehaviorSubject<HomeDropOptions>({ select: false, grades: false });

  focusSearchFieldEvent: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public isLoading: any = {};

  locationError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  onSelectedItem$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  mobileLocation$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  currentKeyword$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private schoolService: SchoolService,
    private sstInfoService: SstInfoService,
    private gService: GSearchService,
    private compareService: CompareService,
    appConfg: AppConfigService,
    private rsiService: RsiService,
    private sstService: SstService,
    private router: Router,

    private httpClient: HttpClient
  ) {
    this.sstInfoService.configuration.basePath =
      appConfg.appConfig?.environment.endPoints.facilitiesServices;
    this.schoolService.configuration.basePath =
      appConfg.appConfig?.environment.endPoints.facilitiesServices;

    this.listeners();
  }

  updateDropOptions(path: string, value: boolean) {
    const dropOptions = this.showDropOptions.getValue();
    dropOptions[path] = value;

    switch (path) {
      case 'keyword':
        dropOptions['location'] = false;
        break;
      case 'location':
        dropOptions['keyword'] = false;
        break;
    }
    this.showDropOptions.next(dropOptions);

    this.updateHomeDropOptions('grades', false);
  }

  updateHomeDropOptions(path: string, value: boolean) {
    const dropOptions = this.showHomeDropOptions.getValue();
    dropOptions[path] = value;

    switch (path) {
      case 'select':
        dropOptions['grades'] = false;
        break;
      case 'grades':
        dropOptions['select'] = false;
        break;
    }
    this.showHomeDropOptions.next(dropOptions);

    this.showDropOptions.next({ location: false, keyword: false });
  }

  getRecentSearch() {
    return [
      ...new Set(
        JSON.parse(window.localStorage.getItem('recent-search') ?? '[]')
      ),
    ];
  }
  setRecentSearch(entry: any) {
    let list = this.getRecentSearch();
    if (list.length >= 5) list.pop();
    var has = list.findIndex((c: any) => c.value == entry.value);
    if (has == -1) {
      list.unshift(entry);
    } else {
      list = list.filter((c: any) => c.value != entry.value) as any;
      list.unshift(entry);
    }
    window.localStorage.setItem('recent-search', JSON.stringify(list));
  }
  resetSearchPageSettings() {
    this.searchPageSettings = {
      addressName: '',
      address: '',
      distance: '',
      keywords: '',
      school: '',
      zip: '',
      sort: '',
      lat: 0,
      lon: 0,
      grades: [],
      filters: [],
      pageSize: 8,
      pageNumber: 0,
      pageTotal: 8,
      searchType: '',
    };
    this.searchPageSettingsChanged$.next(this.searchPageSettings);
    this.sideSearchSettingsChanged$.next(this.searchPageSettings);
    this.selectedLocation$.next(null);
    this.selectedSchool$.next({});
    this.selectedLocationInfo$.next(null);
    this.lastSearchUrl.next('');
    this.schoolSearchResult$.next(null);
    this.schoolSearchResult$.next(null);
    this.searchKeywords$.next('');
    this.updateLocationInfo({});
    window.localStorage.removeItem('sst-filter');
    window.localStorage.removeItem('schoolList');
    window.localStorage.removeItem('searchSettings');
    this.newQuery = true;
    this.compareService.clearCompareState();
    this.compareService.actionRemoveAll();
  }

  listeners() {
    this.searchPageSettingsChanged$.subscribe((filter) => {
      this.searchPageSettings = filter;
    });
    this.isLogged$.subscribe((res) => {
      this.isLogged = res;
    });
  }
  updateLocation(location: any) {
    this.selectedLocation$.next(location);
  }
  updateLocationInfo(location: any) {
    this.selectedLocationInfo$.next(location);
  }
  updateSchool(school: MdlSstSchoolInfo) {
    this.selectedSchool$.next(school);
  }
  actionLogout() {
    this.isLogged$.next(false);
    this.username$.next('');
  }
  actionLogin(displayName: string) {
    this.isLogged$.next(true);
    this.username$.next(displayName);
  }

  actionGetSchoolDetails(schoolCostCenter: string) {
    this.isLoading['photoGrid'] = true;
    this.sstInfoService.v1SstSchoolIdGet(schoolCostCenter).subscribe(
      (response) => {
        console.log(response);
        this.isLoading['photoGrid'] = false;
        this.schoolDetailsInfo$.next(response);
      },
      (err) => {
        this.isLoading['photoGrid'] = false;
      }
    );
  }

  getSchoolDetailsById(costCenterCode: string): Observable<any> {
    return this.sstInfoService.v1SstSchoolIdGet(costCenterCode);
  }

  actionSearch(term: string) {
    let list: KeySearchResult[] = new Array<KeySearchResult>();
    return forkJoin({
      addresses: this.actionSearchAddresses(term),
      schools: this.actionSearchSchools(term),
    }).pipe(
      map((response) =>
        list.concat(response.schools).concat(response.addresses)
      )
    );
  }
  actionSearchSchools(term: string) {
    let list: KeySearchResult[] = new Array<KeySearchResult>();

    return this.getSchoolSearch(term).pipe(
      map((response) => list.concat(response))
    );
  }
  actionSearchkeywords(term: string) {
    let list: KeySearchResult[] = new Array<KeySearchResult>();

    return this.getSchoolSearchKeywords(term).pipe(
      map((response) => list.concat(response))
    );
  }
  actionSearchAddresses(term: string) {
    let list: KeySearchResult[] = new Array<KeySearchResult>();

    return this.getAddresLookup(term).pipe(
      map((response) => list.concat(response))
    );
  }

  actionSearchSettings(filter: SearchPageSettings) {
    this.searchPageSettingsChanged$.next(filter);
  }

  actionSideSearchSettings(filter: SearchPageSettings) {
    this.sideSearchSettingsChanged$.next(filter);
  }

  buildUrl() {
    let qParams: any = {};
    if (this.searchPageSettings.address)
      qParams.address = this.searchPageSettings.address;
    if (this.searchPageSettings.school)
      qParams.school = this.searchPageSettings.school;
    if (this.searchPageSettings.zip) qParams.zip = this.searchPageSettings.zip;
    if (this.searchPageSettings.keywords)
      qParams.tags = this.searchPageSettings.keywords;
    if (this.searchPageSettings.grades.length > 0) {
      qParams.grade = this.searchPageSettings.grades;
    }
    if (this.searchPageSettings.filters?.length > 0) {
      qParams.filter = this.searchPageSettings.filters.map((a: any) => a.name);
    }
    this.router.navigate([`search`], {
      queryParams: qParams,
    });
  }
  actionSearchSources() {
    const self = this;
    this.isLoading['schools'] = true;
    this.locationError.next(false);
    const result$ = this.getSchoolSearchSources().pipe(
      map((response) => response as MdlSstSchoolInfo[])
    );

    result$.subscribe((list) => {
      let newMessages = [...list];
      self.schoolSearchResult$.next(newMessages);
      setTimeout(() => {
        this.isLoading['schools'] = false;
      }, 700);
    });
  }
  actionSideSearchSources() {
    this.sideSearchSettings = this.sideSearchSettingsChanged$.getValue();
    const self = this;
    // this.getSchoolSearchSources().subscribe((g)=>{
    //   this.schoolSearchResult$.next(g)
    // })

    const result$ = this.getSideSearchSources().pipe(
      map((response) => response as MdlSstSchoolInfo[])
    );

    result$.subscribe((list) => {
      let newMessages = [...list];
      self.sideSearchResult$.next(newMessages);
    });
  }
  actionRelatedSchools(locationCode: string): Observable<any> {
    return this.sstService.v1SstRelatedSchoolsGet(locationCode);
  }

  getAddresLookup(term: string): Observable<KeySearchResult[]> {
    let zipSearch = false;
    zipSearch = term.length >= 3 && term.length <= 5 && term[0] == '9';

    if (zipSearch) {
      return this.gService.getZipPrediction(term).pipe(
        map((data: any) =>
          data.hits.hits.map((item: any) => {
            let es: KeySearchResult = {
              id: item._id,
              name: `${item._source.zip}, Los Angeles County, CA, USA`,
              source: 'zip',
            };
            return es;
          })
        )
      );
    }
    return this.gService.getAddressPrediction(term).pipe(
      map((entries: any | null) =>
        entries == null
          ? []
          : entries
              .filter(() => {
                return true;
              })
              .map((entry) => {
                const isLocation = this.isCityLocation(entry.text);
                let es: KeySearchResult = {
                  id: entry.text.replace(/ /g, '-').replace(/,/g, ''),
                  name: `${entry.text}`,
                  source: isLocation ? 'location' : 'address',
                };
                return es;
              })
      )
    );
  }

  private isCityLocation(address: string) {
    const zipCodeRegex = /\b\d{5}(?:-\d{4})?\b/;
    return !zipCodeRegex.test(address);
  }

  getSchoolSearchKeywords(term: string): Observable<KeySearchResult[]> {
    let parsedTerm = term;
    // .split(' ')
    // .map((i) => `${i}*`)
    // .join(' AND ');
    let query = {
      query: {
        query_string: {
          query: parsedTerm,
        },
      },
    };

    return this.schoolService.v1SchoolIndexSearchPost('schools', query).pipe(
      map((response) =>
        response.hits.hits.map((item: any) => {
          let key: KeySearchResult = {
            id: item._id,
            name: item._source.sst.schoolName,
            source: 'school',
          };
          return key;
        })
      )
    );
  }
  getSchoolSearch(term: string): Observable<KeySearchResult[]> {
    let parsedTerm = term;
    let query = {
      query: {
        match_phrase_prefix: {
          'sst.schoolName': {
            query: `${parsedTerm}*`,
          },
        },
      },
    };

    return this.schoolService.v1SchoolIndexSearchPost('schools', query).pipe(
      map((response) =>
        response.hits.hits.map((item: any) => {
          let key: KeySearchResult = {
            id: item._id,
            name: item._source.sst.schoolName,
            source: 'school',
          };
          return key;
        })
      )
    );
  }
  obsg = null;
  getSchoolSearchSources(): Observable<MdlSstSchoolInfo[]> {
    var fromPage =
      this.searchPageSettings.pageNumber * this.searchPageSettings.pageSize;
    return new Observable((obs: Observer<MdlSstSchoolInfo[]>) => {
      this.obsg = obs as any;
      let query: any = {
        size: 8,
        from: fromPage,
        query: {
          query_string: {
            query: '',
          },
        },
        explain: true,
      };

      let geoQuery = {
        size: 8,
        from: fromPage,
        query: {
          bool: {},
        },
        explain: true,
      };
      let sort: any = {
        _geo_distance: {
          'sst.pin.location': {
            lat: 0,
            lon: 0,
          },
          order: 'asc',
        },
      };

      let geo: any = {
        geo_distance: {
          distance: '',
          'sst.pin.location': {
            lat: 0,
            lon: 0,
          },
        },
      };
      let queryStatement = '';
      let keywordFilter = {};
      let filterEs = [] as any;
      if (this.searchPageSettings.school)
        queryStatement += ` ${this.searchPageSettings.school} `;
      var filters = [];
      if (this.searchPageSettings.keywords) {
        var wordsFilters = this.searchPageSettings.keywords.split(' ');
        filters = filters.concat(wordsFilters as any);
        this.setRecentSearch({
          key: 'keyword',
          value: this.searchPageSettings.keywords,
        });
      }
      if (this.searchPageSettings.filters) {
        this.searchPageSettings.filters.forEach((filter: any) => {
          if (filter.filter == 'term') {
            let termFilter = {
              query_string: {},
            };
            termFilter.query_string = {
              query: `sst.${filter.path}:"${filter.value || filter.name.toLowerCase()}"`
            }
            // termFilter.match[`sst.${filter.path}`] =
            //   filter.value || filter.name.toLowerCase();
            filterEs.push(termFilter);
          }
          if (filter.filter == 'exists') {
            let termFilter = {
              exists: {
                field: `sst.${filter.path}`,
              },
            };
            filterEs.push(termFilter);
          }
        });
      }
      if (filters.length > 0) {
        queryStatement += ` ${filters.map((key) => ` (${key}) `).join(' ')} `; //AND
        keywordFilter = {
          multi_match: {
            query: queryStatement,
            fields: [
              'sst.schoolName^4',
              'cms.getToKnowYourSchool.description.en^3',
              'cms.getToKnowYourSchool.description.es^2',
            ],
          },
        };
      }

      let zip = { term: { 'sst.addressZip': '' } };

      if (this.searchPageSettings.zip) {
        of(geoQuery)
          .pipe(
            switchMap((queryContext: any) =>
              this.gService.searchByPlaceZip(this.searchPageSettings.zip).pipe(
                map((place: any) => {
                  place = place.arcGis;
                  var postal_code = place.attributes.Postal;
                  this.searchPageSettings.addressName = place?.address;
                  this.searchPageSettings.lat = place.location.y;
                  this.searchPageSettings.lon = place.location.x;
                  this.setRecentSearch({
                    key: 'zip',
                    value: place?.address,
                  });
                  //Updates selected location
                  const location = { lat: 0, lon: 0 };
                  location.lat = this.searchPageSettings.lat;
                  location.lon = this.searchPageSettings.lon;
                  this.selectedLocation$.next(location);
                  this.searchPageSettingsChanged$.next(this.searchPageSettings);
                  geo['geo_distance']['distance'] = `${
                    parseInt(this.searchPageSettings.distance || '5') * 1.6
                  }km`;
                  geo['geo_distance']['sst.pin.location'].lat =
                    this.searchPageSettings.lat;
                  geo['geo_distance']['sst.pin.location'].lon =
                    this.searchPageSettings.lon;
                  sort['_geo_distance']['sst.pin.location'].lat =
                    this.searchPageSettings.lat;
                  sort['_geo_distance']['sst.pin.location'].lon =
                    this.searchPageSettings.lon;
                  queryContext.query.bool.filter = filterEs;
                  if (this.searchPageSettings.distance != null) {
                    queryContext.query.bool.filter.push(geo);
                  }
                  queryContext.sort = sort;
                  if (this.searchPageSettings.sort == '3') {
                    queryContext.sort = {};
                    queryContext.sort['sst.schoolName.keyword'] = 'asc';

                    //zip.term['sst.addressZip'] = postal_code as any;
                    //queryContext.query.bool.should = zip;
                    //queryContext.query.bool.should.push(zip);
                  }
                  if (queryStatement) {
                    query.query.query_string.query = queryStatement;
                    queryContext.query.bool.must = [];
                    queryContext.query.bool.must.push(query.query);
                    queryContext.query.bool.must.push(keywordFilter);
                  }
                  if (this.searchPageSettings.grades) {
                    const grades = [...this.searchPageSettings.grades];
                    let term = {
                      terms: {
                        'sst.grades.keyword': grades
                          .map((grade) => {
                            if (
                              grade !== 'Adult' &&
                              grade !== 'EEC' &&
                              grade !== 'PRE-K'
                            ) {
                              return grade;
                            } else {
                              return undefined;
                            }
                          })
                          .filter(Boolean),
                      },
                    };
                    this.searchPageSettings.grades.forEach((grade) => {
                      if (grade == 'EEC') {
                        term.terms = {
                          'sst.typeCode.keyword': 'Z',
                        } as any;
                      } else if (grade == 'Adult') {
                        const match = {
                          match: {
                            'sst.gradesOffered': 'Adult',
                          },
                        } as any;
                        queryContext.query.bool.filter.push(match);
                      } else if (grade == 'PRE-K') {
                        const match = {
                          match: {
                            'sst.gradesOffered': 'Preschool',
                          },
                        } as any;
                        queryContext.query.bool.filter.push(match);
                      }
                    });
                    if (term.terms['sst.grades.keyword'].length > 0) {
                      queryContext.query.bool.filter.push(term);
                    }
                  }

                  return queryContext;
                }),
                switchMap((qR) =>
                  this.schoolService
                    .v1SchoolIndexSearchPost('schools', qR)
                    .pipe(
                      map((rs) => {
                        this.searchPageSettings.pageTotal = rs.hits.total.value;
                        return rs.hits.hits.map((r: any) => {
                          r._maxScore = rs.hits.max_score;
                          r.distance = this.distanceBetweenCoordinates(
                            parseFloat(this.searchPageSettings.lat as any),
                            parseFloat(this.searchPageSettings.lon as any),
                            parseFloat(r._source?.sst.pin?.location.lat as any),
                            parseFloat(r._source?.sst.pin?.location.lon as any)
                          );
                          return r;
                        }) as EsRow[];
                      })
                    )
                )
              )
            ),
            switchMap((rows) => this.compareService.mapFromEsrow(rows))
          )
          .subscribe((rs) => {
            obs.next(rs);
          });
      } else if (this.searchPageSettings.address) {
        const addressRequest$ = of(geoQuery)
          .pipe(
            switchMap((queryContext: any) =>
              this.gService
                .searchByPlaceId(this.searchPageSettings.address)
                .pipe(
                  map((place: any) => {
                    var argGis = place['arcGis'];

                    var streetName = argGis.attributes.StName || null;
                    var streetNumber = argGis.attributes.AddNum || null;
                    var streetType = argGis.attributes.StType || null;
                    var navDirection = argGis.attributes.StPreDir || null;
                    var postalCode = argGis.attributes.Postal || null;

                    this.rsiSettings.streetNumber = streetNumber || null;
                    this.rsiSettings.navDirection = navDirection;
                    this.rsiSettings.streetName = streetName || null;
                    this.rsiSettings.streetType = streetType || null;
                    this.rsiSettings.zipCode = postalCode || null;

                    this.searchPageSettings.addressName = argGis?.address;
                    this.setRecentSearch({
                      key: 'address',
                      value: argGis?.address,
                    });
                    this.searchPageSettings.lat = argGis.location.y;

                    this.searchPageSettings.lon = argGis.location.x;
                    this.searchPageSettingsChanged$.next(
                      this.searchPageSettings
                    );
                    const distance = this.searchPageSettings.distance
                      ? `${parseInt(this.searchPageSettings.distance) * 1.6}km`
                      : '8km';
                    geo['geo_distance']['distance'] = distance;
                    geo['geo_distance']['sst.pin.location'].lat =
                      this.searchPageSettings.lat;
                    geo['geo_distance']['sst.pin.location'].lon =
                      this.searchPageSettings.lon;

                    sort['_geo_distance']['sst.pin.location'].lat =
                      this.searchPageSettings.lat;
                    sort['_geo_distance']['sst.pin.location'].lon =
                      this.searchPageSettings.lon;

                    if (this.searchPageSettings.sort == '3') {
                      // ABC order
                      sort = [];
                      sort.push({ 'sst.schoolName.keyword': 'asc' });
                    }
                    queryContext.query.bool.filter = filterEs;
                    if (this.searchPageSettings.distance != null) {
                      queryContext.query.bool.filter.push(geo);
                    }
                    queryContext.sort = sort;

                    const location = { lat: 0, lon: 0 };
                    location.lat = geo['geo_distance']['sst.pin.location'].lat;
                    location.lon = geo['geo_distance']['sst.pin.location'].lon;
                    this.selectedLocation$.next(location);
                    // if (postal_code) {
                    //   // zip.term['sst.addressZip'] = postal_code.short_name;
                    //   // queryContext.query.bool.should = zip;
                    // }
                    if (queryStatement) {
                      query.query.query_string.query = queryStatement;
                      queryContext.query.bool.must = [];
                      queryContext.query.bool.must.push(query.query);
                      queryContext.query.bool.must.push(keywordFilter);
                    }
                    if (this.searchPageSettings.grades) {
                      const grades = [...this.searchPageSettings.grades];
                      let term = {
                        terms: {
                          'sst.grades.keyword': grades
                            .map((grade) => {
                              if (
                                grade !== 'Adult' &&
                                grade !== 'EEC' &&
                                grade !== 'PRE-K'
                              ) {
                                return grade;
                              } else {
                                return undefined;
                              }
                            })
                            .filter(Boolean),
                        },
                      };

                      this.searchPageSettings.grades.forEach((grade) => {
                        if (grade == 'EEC') {
                          term.terms = {
                            'sst.typeCode.keyword': 'Z',
                          } as any;
                        } else if (grade == 'Adult') {
                          const match = {
                            match: {
                              'sst.gradesOffered': 'Adult',
                            },
                          } as any;
                          queryContext.query.bool.filter.push(match);
                        } else if (grade == 'PRE-K') {
                          const match = {
                            match: {
                              'sst.gradesOffered': 'Preschool',
                            },
                          } as any;
                          queryContext.query.bool.filter.push(match);
                        }
                      });
                      if (term.terms['sst.grades.keyword'].length > 0) {
                        queryContext.query.bool.filter.push(term);
                      }
                    }

                    return queryContext;
                  }),
                  switchMap((qR) =>
                    this.schoolService
                      .v1SchoolIndexSearchPost('schools', qR)
                      .pipe(
                        map((rs) => {
                          this.searchPageSettings.pageTotal =
                            rs.hits.total.value;
                          //     this.searchPageSettingsChanged$.next(this.searchPageSettings);
                          return rs.hits.hits.map((r: any) => {
                            r._maxScore = rs.hits.max_score;
                            r.distance = this.distanceBetweenCoordinates(
                              parseFloat(this.searchPageSettings.lat as any),
                              parseFloat(this.searchPageSettings.lon as any),
                              parseFloat(
                                r._source?.sst.pin?.location.lat as any
                              ),
                              parseFloat(
                                r._source?.sst.pin?.location.lon as any
                              )
                            );
                            return r;
                          }) as EsRow[];
                        })
                      )
                  )
                )
            ),
            switchMap((rows) => this.compareService.mapFromEsrow(rows))
          )
          .subscribe((schoolList) => {
            obs.next(schoolList);
          });
      } else {
        query.query.query_string.query = queryStatement;
        this.setRecentSearch({
          key: 'keyword',
          value: this.searchPageSettings.keywords,
        });
        var statement = query.query;
        query.query = {
          bool: {
            should: [statement, keywordFilter],
            filter: filterEs,
            minimum_should_match: 1,
          },
        };

        if (this.searchPageSettings.sort == '3') {
          query.sort = {};
          query.sort['sst.schoolName.keyword'] = 'asc';
        }

        if (this.searchPageSettings.grades.length > 0) {
  

          const grades = [...this.searchPageSettings.grades];
          let term = {
            terms: {
              'sst.grades.keyword': grades
                .map((grade) => {
                  if (
                    grade !== 'Adult' &&
                    grade !== 'EEC' &&
                    grade !== 'PRE-K'
                  ) {
                    return grade;
                  } else {
                    return undefined;
                  }
                })
                .filter(Boolean),
            },
          };

          const specialCaseGrades: any[] = [];
          this.searchPageSettings.grades.forEach((grade) => {
            if (grade == 'EEC') {
              term.terms = {
                'sst.typeCode.keyword': 'Z',
              } as any;
            } else if (grade == 'Adult') {
              const match = {
                match: {
                  'sst.gradesOffered': 'Adult',
                },
              } as any;
              query.query.bool.filter.push(match);
            } else if (grade == 'PRE-K') {
              const match = {
                match: {
                  'sst.gradesOffered': 'Preschool',
                },
              } as any;
              query.query.bool.filter.push(match);
            }
          });
          if (term.terms['sst.grades.keyword'].length > 0) {
            query.query.bool.filter.push(term);
          }


          this.setRecentSearch({ key: 'keyword', value: term });
        } else {
          
        }
        this.schoolService
          .v1SchoolIndexSearchPost('schools', query)
          .pipe(
            map((rs) => {
              this.searchPageSettings.pageTotal = rs.hits.total.value;
              //this.searchPageSettingsChanged$.next(this.searchPageSettings);
              return rs.hits.hits.map((r: any) => {
                r._maxScore = rs.hits.max_score;
                r.distance = this.distanceBetweenCoordinates(
                  parseFloat(this.searchPageSettings.lat as any),
                  parseFloat(this.searchPageSettings.lon as any),
                  parseFloat(r._source?.sst.pin?.location.lat as any),
                  parseFloat(r._source?.sst.pin?.location.lon as any)
                );
                return r;
              }) as EsRow[];
            }),
            switchMap((rows) => this.compareService.mapFromEsrow(rows))
          )
          .subscribe((rs) => {
            obs.next(rs);
          });
      }
    });
  }

  getSideSearchSources(): Observable<MdlSstSchoolInfo[]> {
    //let parsedTerm = tag.split(' ').map((i)=>`${i}*`).join(' AND ')
    var fromPage =
      this.sideSearchSettings.pageNumber * this.sideSearchSettings.pageSize;
    return new Observable((obs: Observer<MdlSstSchoolInfo[]>) => {
      this.obsg = obs as any;
      let query: any = {
        size: 8,
        from: fromPage,
        query: {
          query_string: {
            query: '',
          },
        },
      };
      let geoQuery = {
        size: 8,
        from: fromPage,
        query: {
          bool: {},
        },
      };
      let sort = {
        _geo_distance: {
          'sst.pin.location': {
            lat: 0,
            lon: 0,
          },
          order: 'asc',
        },
      };
      let geo: any = {
        geo_distance: {
          distance: '',
          'sst.pin.location': {
            lat: 0,
            lon: 0,
          },
        },
      };
      let queryStatement = '';
      let keywordFilter = {};
      let filterEs = [] as any;
      if (this.sideSearchSettings.school)
        queryStatement += ` ${this.sideSearchSettings.school} `;
      var filters = [];
      if (this.sideSearchSettings.keywords) {
        var wordsFilters = this.sideSearchSettings.keywords.split(' ');
        filters = filters.concat(wordsFilters as any);
      }
      if (this.sideSearchSettings.filters) {
        this.sideSearchSettings.filters.forEach((filter: any) => {
          if (filter.filter == 'term') {
            let termFilter = {
              match: {},
            };
            termFilter.match[`sst.${filter.path}`] = filter.name.toLowerCase();
            filterEs.push(termFilter);
          }
          if (filter.filter == 'exists') {
            let termFilter = {
              exists: {
                field: `sst.${filter.path}`,
              },
            };
            filterEs.push(termFilter);
          }
        });
      }
      if (filters.length > 0) {
        queryStatement += ` ${filters.map((key) => ` (${key}) `).join(' ')} `; //AND
        keywordFilter = {
          multi_match: {
            query: queryStatement,
            fields: [
              'sst.schoolName^3',
              'cms.getToKnowYourSchool.description.en^2',
            ],
          },
        };
      }

      let zip = { term: { 'sst.addressZip': '' } };
      if (this.sideSearchSettings.zip) {
        of(geoQuery)
          .pipe(
            switchMap((queryContext: any) =>
              this.gService.searchByPlaceZip(this.sideSearchSettings.zip).pipe(
                map((place: any) => {
                  place = place[0];
                  var postal_code = this.sideSearchSettings.zip;
                  this.sideSearchSettings.addressName =
                    place?.formatted_address;
                  this.sideSearchSettings.lat = place?.geometry.location.lat();
                  this.sideSearchSettings.lon = place?.geometry.location.lng();
                  //Updates selected location
                  const location = { lat: 0, lon: 0 };
                  location.lat = this.sideSearchSettings.lat;
                  location.lon = this.sideSearchSettings.lon;
                  // this.selectedLocation$.next(location);
                  this.sideSearchSettingsChanged$.next(this.sideSearchSettings);
                  geo['geo_distance']['distance'] = `${
                    parseInt(this.sideSearchSettings.distance || '5') * 1.6
                  }km`;
                  geo['geo_distance']['sst.pin.location'].lat =
                    place?.geometry.location.lat();
                  geo['geo_distance']['sst.pin.location'].lon =
                    place?.geometry.location.lng();
                  sort['_geo_distance']['sst.pin.location'].lat =
                    place?.geometry.location.lat();
                  sort['_geo_distance']['sst.pin.location'].lon =
                    place?.geometry.location.lng();
                  queryContext.query.bool.filter = filterEs;
                  if (this.sideSearchSettings.distance != null) {
                    queryContext.query.bool.filter.push(geo);
                    queryContext.sort = sort;
                  }

                  if (postal_code) {
                    //zip.term['sst.addressZip'] = postal_code as any;
                    //queryContext.query.bool.should = zip;
                    //queryContext.query.bool.should.push(zip);
                  }
                  if (queryStatement) {
                    query.query.query_string.query = queryStatement;
                    queryContext.query.bool.must = [];
                    queryContext.query.bool.must.push(query.query);
                    queryContext.query.bool.must.push(keywordFilter);
                  }
                  if (this.searchPageSettings.grades) {
                    this.searchPageSettings.grades.forEach((grade) => {
                      let term = {
                        term: {
                          'sst.grades.keyword': grade,
                        },
                      };

                      if (grade == 'EEC') {
                        term.term = {
                          'sst.typeCode.keyword': 'Z',
                        } as any;
                      } else if (grade == 'Adult') {
                        term = {
                          terms: {
                            'sst.typeCode.keyword': ['A', 'L', 'M'],
                          },
                        } as any;
                      }
                      console.log('search term', term);

                      queryContext.query.bool.filter.push(term);
                    });
                  }

                  return queryContext;
                }),
                switchMap((qR) =>
                  this.schoolService
                    .v1SchoolIndexSearchPost('schools', qR)
                    .pipe(
                      map((rs) => {
                        this.sideSearchSettings.pageTotal = rs.hits.total.value;
                        return rs.hits.hits.map((r: any) => {
                          r._maxScore = rs.hits.max_score;
                          r.distance = this.distanceBetweenCoordinates(
                            parseFloat(this.sideSearchSettings.lat as any),
                            parseFloat(this.sideSearchSettings.lon as any),
                            parseFloat(r._source?.sst.pin?.location.lat as any),
                            parseFloat(r._source?.sst.pin?.location.lon as any)
                          );
                          return r;
                        }) as EsRow[];
                      })
                    )
                )
              )
            ),
            switchMap((rows) => this.compareService.mapFromEsrow(rows))
          )
          .subscribe((rs) => {
            obs.next(rs);
          });
      } else if (this.sideSearchSettings.address) {
        const addressRequest$ = of(geoQuery)
          .pipe(
            switchMap((queryContext: any) =>
              this.gService
                .searchByPlaceId(this.sideSearchSettings.address)
                .pipe(
                  map((place: any) => {
                    var postal_code = place?.address_components.find(
                      (ac: any) => ac.types.includes('postal_code')
                    );
                    var streetNumber = place?.address_components.find(
                      (ac: any) => ac.types.includes('street_number')
                    );
                    var route = place?.address_components.find((ac: any) =>
                      ac.types.includes('route')
                    );

                    var streetName = '';
                    var streetType = '';
                    var navDirection = '';
                    if (route) {
                      if (route.short_name) {
                        var parts = route.short_name.split(' ');
                        streetType = parts.pop();
                        streetName = parts.pop();
                        navDirection = parts.pop() || null;
                      }
                    }
                    this.rsiSettings.streetNumber =
                      streetNumber?.short_name || null;
                    this.rsiSettings.navDirection = navDirection;
                    this.rsiSettings.streetName = streetName || null;
                    this.rsiSettings.streetType = streetType || null;
                    this.rsiSettings.zipCode = postal_code?.short_name || null;

                    this.sideSearchSettings.addressName =
                      place?.formatted_address;
                    this.sideSearchSettings.lat =
                      place?.geometry.location.lat();
                    this.sideSearchSettings.lon =
                      place?.geometry.location.lng();
                    this.sideSearchSettingsChanged$.next(
                      this.sideSearchSettings
                    );
                    const distance = this.sideSearchSettings.distance
                      ? `${parseInt(this.sideSearchSettings.distance) * 1.6}km`
                      : '8km';
                    geo['geo_distance']['distance'] = distance;
                    geo['geo_distance']['sst.pin.location'].lat =
                      place?.geometry.location.lat();
                    geo['geo_distance']['sst.pin.location'].lon =
                      place?.geometry.location.lng();

                    sort['_geo_distance']['sst.pin.location'].lat =
                      place?.geometry.location.lat();
                    sort['_geo_distance']['sst.pin.location'].lon =
                      place?.geometry.location.lng();

                    queryContext.query.bool.filter = filterEs;
                    if (this.sideSearchSettings.distance != null) {
                      queryContext.query.bool.filter.push(geo);
                      queryContext.sort = sort;
                    }

                    const location = { lat: 0, lon: 0 };
                    location.lat = geo['geo_distance']['sst.pin.location'].lat;
                    location.lon = geo['geo_distance']['sst.pin.location'].lon;
                    this.selectedLocation$.next(location);
                    if (postal_code) {
                      // zip.term['sst.addressZip'] = postal_code.short_name;
                      // queryContext.query.bool.should = zip;
                    }
                    if (queryStatement) {
                      query.query.query_string.query = queryStatement;
                      queryContext.query.bool.must = [];
                      queryContext.query.bool.must.push(query.query);
                      queryContext.query.bool.must.push(keywordFilter);
                    }
                    if (this.searchPageSettings.grades) {
                      this.searchPageSettings.grades.forEach((grade) => {
                        let term = {
                          term: {
                            'sst.grades.keyword': grade,
                          },
                        };

                        if (grade == 'EEC') {
                          term.term = {
                            'sst.typeCode.keyword': 'Z',
                          } as any;
                        } else if (grade == 'Adult') {
                          term = {
                            terms: {
                              'sst.typeCode.keyword': ['A', 'L', 'M'],
                            },
                          } as any;
                        }
                        console.log('search term', term);

                        queryContext.query.bool.filter.push(term);
                      });
                    }

                    return queryContext;
                  }),
                  switchMap((qR) =>
                    this.schoolService
                      .v1SchoolIndexSearchPost('schools', qR)
                      .pipe(
                        map((rs) => {
                          this.sideSearchSettings.pageTotal =
                            rs.hits.total.value;
                          this.sideSearchSettingsChanged$.next(
                            this.searchPageSettings
                          );
                          return rs.hits.hits.map((r: any) => {
                            r._maxScore = rs.hits.max_score;
                            r.distance = this.distanceBetweenCoordinates(
                              parseFloat(this.sideSearchSettings.lat as any),
                              parseFloat(this.sideSearchSettings.lon as any),
                              parseFloat(
                                r._source?.sst.pin?.location.lat as any
                              ),
                              parseFloat(
                                r._source?.sst.pin?.location.lon as any
                              )
                            );
                            return r;
                          }) as EsRow[];
                        })
                      )
                  )
                )
            ),
            switchMap((rows) => this.compareService.mapFromEsrow(rows))
          )
          .subscribe((schoolList) => {
            obs.next(schoolList);
          });
      } else {
        query.query.query_string.query = queryStatement;
        if (this.sideSearchSettings.grades) {
          var statement = query.query;
          query.query = {
            bool: {
              should: [statement, keywordFilter],
              filter: filterEs,
              minimum_should_match: 1,
            },
          };
          if (this.searchPageSettings.grades) {
            this.searchPageSettings.grades.forEach((grade) => {
              let term = {
                term: {
                  'sst.grades.keyword': grade,
                },
              };

              if (grade == 'EEC') {
                term.term = {
                  'sst.typeCode.keyword': 'Z',
                } as any;
              } else if (grade == 'Adult') {
                term = {
                  terms: {
                    'sst.typeCode.keyword': ['A', 'L', 'M'],
                  },
                } as any;
              }
              console.log('search term', term);

              query.query.bool.filter.push(term);
            });
          }
        }
        this.schoolService
          .v1SchoolIndexSearchPost('schools', query)
          .pipe(
            map((rs) => {
              this.sideSearchSettings.pageTotal = rs.hits.total.value;
              this.sideSearchSettingsChanged$.next(this.searchPageSettings);
              return rs.hits.hits.map((r: any) => {
                r._maxScore = rs.hits.max_score;
                r.distance = this.distanceBetweenCoordinates(
                  parseFloat(this.sideSearchSettings.lat as any),
                  parseFloat(this.sideSearchSettings.lon as any),
                  parseFloat(r._source?.sst.pin?.location.lat as any),
                  parseFloat(r._source?.sst.pin?.location.lon as any)
                );
                return r;
              }) as EsRow[];
            }),
            switchMap((rows) => this.compareService.mapFromEsrow(rows))
          )
          .subscribe((rs) => {
            obs.next(rs);
          });
      }
    });
  }
  distanceBetweenCoordinates(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number {
    const R = 3959; // radius of the Earth in miles
    const phi1 = (lat1 * Math.PI) / 180;
    const phi2 = (lat2 * Math.PI) / 180;
    const deltaPhi = ((lat2 - lat1) * Math.PI) / 180;
    const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) *
        Math.cos(phi2) *
        Math.sin(deltaLambda / 2) *
        Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }
}

interface DropOptions {
  keyword: boolean;
  location: boolean;
}

interface HomeDropOptions {
  select: boolean;
  grades: boolean;
}
